import { useCallback, useEffect, useState } from 'react'

import filter from 'lodash/filter'
import orderBy from 'lodash/orderBy'
import { useDebounce, useUpdateEffect } from 'react-use'

import { PricingTier } from '~apis'
import { ITableColumn } from '~common'

import { useQueryGetPricingTiers } from '../services/pricing-tier.service'
import { useTableConfig } from './useTableConfig'

export const PricingTierTableColumns: ITableColumn[] = [
  {
    field: 'id',
    displayName: 'Id',
    align: 'left',
    width: 300
  },
  {
    field: 'name',
    displayName: 'Name',
    align: 'left',
    width: 200
  },
  {
    field: 'in_use',
    displayName: 'In use',
    align: 'left',
    width: 100,
    disableSorting: true
  },
  {
    field: 'created_date',
    displayName: 'Created date',
    align: 'left',
    width: 200
  },
  {
    field: 'updated_date',
    displayName: 'Updated date',
    align: 'left',
    width: 200
  },
  {
    field: 'created_by',
    displayName: 'Created by',
    align: 'left',
    width: 200
  },
  {
    field: 'updated_by',
    displayName: 'Updated by',
    align: 'left',
    width: 200
  },
  {
    field: 'delete',
    displayName: '',
    align: 'left',
    width: 100
  }
]

export const usePricingTierTable = () => {
  const [pricingTiers, setPricingTiers] = useState<PricingTier[]>([])
  const [filteredPricingTiers, setFilteredPricingTiers] = useState<
    PricingTier[]
  >([])
  const [paginatedPricingTiers, setPaginatedPricingTiers] = useState<
    PricingTier[]
  >([])

  const [searchQuery, setSearchQuery] = useState<string>('')
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('')

  useDebounce(() => setDebouncedSearchQuery(searchQuery), 500, [searchQuery])

  const { isFetching, data, isFetched } = useQueryGetPricingTiers()

  useEffect(() => {
    if (isFetched) {
      setPricingTiers(
        (filter(
          (data?.data as unknown as Record<string, PricingTier[]>)
            ?.pricing_tiers,
          (pricingTier: PricingTier) => pricingTier && pricingTier.id
        ) as PricingTier[]) || []
      )
    }
  }, [data, isFetched])

  const {
    data: { pageSize, page, sort },
    actions: { changePage, changePageSize, changeSort }
  } = useTableConfig('admin-portal-pricing-tiers')

  // filter user by search query
  useUpdateEffect(() => {
    if (debouncedSearchQuery !== '') {
      const queryResults = pricingTiers.filter(
        (pricingTier) =>
          pricingTier.id?.includes(debouncedSearchQuery) ||
          pricingTier.name
            ?.toLowerCase()
            .includes(debouncedSearchQuery?.toLowerCase())
      )

      setFilteredPricingTiers(queryResults)
    } else {
      setFilteredPricingTiers(pricingTiers)
    }
  }, [debouncedSearchQuery, pricingTiers])

  // sort organization table column
  useUpdateEffect(() => {
    if (sort.split(':')?.length > 0) {
      type SortOrder = boolean | 'asc' | 'desc'
      const [field, order] = sort.split(':')

      setPricingTiers((prevState) =>
        orderBy(prevState, [field], [order as SortOrder])
      )
    }
  }, [sort])

  // new search results, reset current page to 1
  useUpdateEffect(() => {
    setPaginatedPricingTiers(filteredPricingTiers.slice(0, pageSize))
    changeCurrentPage(1)
  }, [filteredPricingTiers])

  // normal pagination action
  useUpdateEffect(() => {
    setPaginatedPricingTiers(
      filteredPricingTiers.slice((page - 1) * pageSize, page * pageSize)
    )
  }, [page, pageSize])

  const changeCurrentPage = useCallback(
    (newPage: number) => {
      changePage(newPage)
    },
    [changePage]
  )

  const changeCurrentPageSize = useCallback(
    (pageSize: number) => {
      changePageSize(pageSize)
    },
    [changePageSize]
  )

  const changeCurrentSort = useCallback(
    (sort: string) => {
      changeSort(sort)
    },
    [changeSort]
  )

  return {
    data: paginatedPricingTiers,
    originalData: pricingTiers,
    dataCount: filteredPricingTiers?.length,
    isLoading: isFetching,
    columns: PricingTierTableColumns,
    pagination: {
      currentPage: page,
      currentPageSize: pageSize,
      currentSort: sort,
      changeCurrentPage,
      changeCurrentPageSize,
      changeCurrentSort
    },
    searchQuery,
    setSearchQuery
  }
}
