/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  makeStyles
} from '@epilot/base-elements'
import { getRoleId } from '@epilot/permissions/utils'
import { EpilotIcon } from '@epilot360/icons'
import { useSnackbar } from 'notistack'

import { Grant } from '../../apis/permissions-client'
import { PricingTier } from '../../apis/pricing-tier-client'

import './RootRolePreview.css'

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  accordionDetail: {
    maxHeight: '500px',
    overflow: 'auto'
  }
})

interface Props {
  pricingTier: PricingTier
}
export const RootRolePreview = (props: Props) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  return (
    <Box className={classes.root} marginTop={'24px'}>
      <Accordion defaultExpanded={false}>
        <AccordionSummary>Root Role Template</AccordionSummary>
        <AccordionDetails className={classes.accordionDetail}>
          <pre
            className="root-role-template-preview"
            onClick={() => {
              navigator.clipboard.writeText(
                JSON.stringify(getRootRolePreview(props.pricingTier), null, 2)
              )
              enqueueSnackbar('JSON copied to clipboard', {
                variant: 'info'
              })
            }}
          >
            <code>
              {JSON.stringify(getRootRolePreview(props.pricingTier), null, 2)}
            </code>
            <div className="copy-indicator">
              <EpilotIcon fill="#FF6A3F" name="content_copy" />
            </div>
          </pre>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

const getRootRolePreview = (pricingTier: PricingTier) => {
  const rootRole = {
    id: getRoleId({ orgId: '0', slug: 'root' }),
    name: 'Organization Permissions',
    slug: 'root',
    organization_id: '0',
    type: 'org_role',
    pricing_tier: pricingTier?.name,
    grants: getGrantsForPricingTier(pricingTier)
  }

  return rootRole
}

/** Orgs who do have a Pricing Tier: Business / Professional / etc. */
const getGrantsForPricingTier = (pricingTier: PricingTier) => {
  const grants: Grant[] = []

  // all organizations have access to entities
  grants.push({
    action: 'entity:*'
  })

  // access to schema via entity builder
  grants.push({
    action: 'schema:*'
  })

  // user management
  grants.push({
    action: 'user:*'
  })

  // role management
  grants.push({
    action: 'role:*'
  })

  // organization management
  grants.push({
    action: 'organization:*'
  })

  // access to messaging
  grants.push({
    action: 'message:*'
  })

  // workflow builder
  grants.push({
    action: 'workflow:definition:*'
  })

  // workflow execution
  grants.push({
    action: 'workflow:execution:*'
  })

  // 360 dashboard
  grants.push({
    action: 'dashboard:*'
  })

  // access to design builder
  grants.push({
    action: 'design:*'
  })

  // access to automation
  grants.push({
    action: 'automation:*'
  })

  // access to tokens
  grants.push({
    action: 'token:*'
  })

  // notes
  grants.push({
    action: 'note:*'
  })

  // email settings
  grants.push({
    action: 'email_setting:*'
  })

  // epilot email address
  grants.push({
    action: 'epilot_email_address:*'
  })

  // meter readings
  grants.push({
    action: 'meter_reading:*'
  })

  grants.push(...convertSettingsToGrants(pricingTier))

  return grants
}

const convertSettingsToGrants = (pricingTier: PricingTier) => {
  if (!pricingTier) {
    return []
  }

  const grants: Grant[] = []

  const settings = {
    ...(pricingTier.settings || {}),
    ...(pricingTier.override_settings || {})
  }

  Object.entries(settings).forEach(([key, value]) => {
    // only process boolean value
    if (value?.enabled === true) {
      const action = `${key}:*`

      grants.push({ action })
    }
  })

  return grants
}
