import React from 'react'

import { CircularProgress } from '@epilot/base-elements'

import { EntityItem } from '~apis'
import { ITableColumn } from '~common'
import { usePartnerTable } from '~hooks'
import { useDataPoints } from '~providers'

import { MainTable } from '../molecules'

export const Partners = () => {
  const {
    isLoading,
    data: partners,
    dataCount,
    pagination,
    columns
  } = usePartnerTable()

  const { mergedDataPointMap, loading: loadingDataPoint } = useDataPoints()

  const renderTableCell = (
    column: ITableColumn,
    partner: EntityItem
  ): React.ReactNode => {
    let component: React.ReactNode

    switch (column?.field) {
      case 'actual_users':
        if (loadingDataPoint) {
          component = <CircularProgress size={20} />
        } else if (!partner?.partner_org_id) {
          component = 0
          break
        }
        component = mergedDataPointMap[partner?.partner_org_id]?.actual_users
        break
      case 'max_users':
        if (loadingDataPoint) {
          component = <CircularProgress size={20} />
        } else if (!partner?.partner_org_id) {
          component = 0
          break
        }
        component =
          mergedDataPointMap[partner?.partner_org_id]?.max_users_last_month
        break
      default:
        component = partner[column?.field] as React.ReactNode
        break
    }

    return component
  }

  return (
    <MainTable
      {...pagination}
      columns={columns}
      customRenderTableCell={renderTableCell}
      data={partners}
      dataCount={dataCount}
      isLoading={isLoading}
      locale={''}
    />
  )
}
