import React, {
  FC,
  MutableRefObject,
  createContext,
  useCallback,
  useContext,
  useRef,
  useState
} from 'react'

import { Box, CircularProgress, Drawer360 } from '@epilot/base-elements'

import { SvelteJSONEditor } from '~components'

import { isValidJSON } from '../common/utils'

type OnSaveCallback = (value: unknown) => void
type JSONValue = Record<string, unknown>

export interface IJSONEditorDrawerContextProvider {
  open: boolean
  value: JSONValue
  toggleOpen: () => void
  setTitle: React.Dispatch<React.SetStateAction<string>>
  setJson: React.Dispatch<React.SetStateAction<string>>
  callback: MutableRefObject<OnSaveCallback | null>
}

const JSONEditorDrawerContext = createContext<IJSONEditorDrawerContextProvider>(
  {
    open: false,
    value: {},
    toggleOpen: () => null,
    setJson: () => null,
    setTitle: () => null,
    callback: null
  }
)

export const JSONEditorDrawerContextProvider: FC = ({ children }) => {
  const [open, setOpen] = useState(false)
  const [json, setJson] = useState('')
  const [value, setValue] = useState<Record<string, unknown>>()
  const [title, setTitle] = useState('Role')
  const callback = useRef<OnSaveCallback | null>(null)

  const toggleOpen = useCallback(() => {
    if (!open) {
      setValue(null)
    }
    setOpen(!open)
  }, [open])

  const onSave = useCallback(async () => {
    callback.current?.(value)

    toggleOpen()
  }, [toggleOpen, value])

  return (
    <JSONEditorDrawerContext.Provider
      value={{
        open,
        value,
        toggleOpen,
        setJson,
        setTitle,
        callback
      }}
    >
      <Drawer360
        onClose={toggleOpen}
        onDiscard={toggleOpen}
        onSave={onSave}
        open={open}
        showActionButtons={Boolean(callback.current)}
        size="one-third"
        title={title}
        unsavedChanges={Boolean(value)}
      >
        <React.Suspense fallback={<CircularProgress />}>
          <Box
            display="flex"
            height={'100%'}
            sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0
            }}
            width={'100%'}
          >
            {open && (
              <SvelteJSONEditor
                content={{ text: json }}
                indentation={2}
                key={title}
                navigationBar={false}
                onChange={(content) => {
                  if (content['text'] !== undefined) {
                    setJson(content['text'])
                    if (isValidJSON(content['text'])) {
                      setValue(JSON.parse(content['text']))
                    }
                  }
                  if (content['json'] !== undefined) {
                    setValue(content['json'])
                  }
                }}
                readOnly={!callback.current}
              />
            )}
          </Box>
        </React.Suspense>
      </Drawer360>

      {children}
    </JSONEditorDrawerContext.Provider>
  )
}

interface JSONEditorParams {
  onSave?: OnSaveCallback
}
export const useJSONEditorDrawer = (params: JSONEditorParams = {}) => {
  const context = useContext(JSONEditorDrawerContext)

  if (params.onSave !== context.callback.current) {
    context.callback.current = params.onSave
  }

  return context
}
