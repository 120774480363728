import React, { memo, useMemo, useRef, useState } from 'react'

import {
  Box,
  CircularProgress,
  createStyles,
  H6,
  makeStyles,
  Pagination,
  Paper,
  ResizeTableCell,
  ResizeTableContextProvider,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@epilot/base-elements'

// import uniqBy from 'lodash/uniqBy'
import {
  getNewSortDirection,
  ITableColumn,
  ITableDataProps,
  SortModel,
  splitSortIntoFieldAndDirection
} from '~common'

import { EmptyIllustration, StatusChip } from '../atoms'

const useClasses = makeStyles(() =>
  createStyles({
    cursorPointer: {
      cursor: 'pointer'
    },
    listIcon: {
      marginRight: 12
    },
    paper: {
      flexDirection: 'column',
      flex: 1,
      position: 'relative'
    },
    tableRowRoot: {
      cursor: 'pointer'
    },
    container: {
      height: 'calc(100vh - 250px)'
    },
    checkBoxCellPadding: {
      paddingLeft: `16px !important`,
      paddingRight: `16px !important`
    },
    bold: {
      fontWeight: 600
    },
    loadingContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: 'rgba(23, 43, 77 ,0.1)',
      zIndex: 3
    },
    emptyTableCell: {
      position: 'absolute',
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%'
    },
    menuRowText: {
      '& .epilot360-messaging-MuiTypography-root': {
        textTransform: 'capitalize'
      }
    },
    notFoundContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    paginationContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 24
    }
  })
)

const renderTableCell = (
  column: ITableColumn,
  data: unknown
): React.ReactNode => {
  let component: React.ReactNode

  switch (column?.field) {
    case 'status':
      component = (
        <Box display="flex">
          <StatusChip
            status={(data as Record<string, unknown>)?.status as string}
          ></StatusChip>
        </Box>
      )
      break

    default:
      component = data?.[column?.field] as React.ReactNode
      break
  }

  return component
}

type Props = ITableDataProps & {
  customRenderTableCell?: (
    column: ITableColumn,
    data: unknown
  ) => React.ReactNode
  locale: string
  columns: ITableColumn[]
  onRowClick?: (row: unknown) => void
}

const Table: React.FC<Props> = ({
  currentSort,
  currentPage = 1,
  currentPageSize = 10,
  changeCurrentPage,
  changeCurrentPageSize,
  changeCurrentSort,
  isLoading = false,
  data = [],
  dataCount = 0,
  columns = [],
  customRenderTableCell = renderTableCell,
  onRowClick = () => null
}) => {
  const classes = useClasses()

  const [hideHorizontalScroll, setHideHorizontalScroll] = useState(false)
  const [tableColumns, setTableColumns] = useState(columns)

  const tableContainerRef = useRef<HTMLDivElement>(null)

  const onSortChange = (sortModel: SortModel) => () => {
    changeCurrentSort(`${sortModel.field}:${sortModel.direction}`)
  }

  const sortData = useMemo(
    () => splitSortIntoFieldAndDirection(currentSort),
    [currentSort]
  )

  const onPageSizeChange = (pageSize: number) => {
    changeCurrentPageSize(pageSize)
  }

  const hideScroll = (value: boolean) => () => {
    setHideHorizontalScroll(value)
  }

  const onWidthChange = (updatedColumn: ITableColumn) => (newWidth: number) => {
    const newColumns = columns.map((column) => {
      if (column.field !== updatedColumn.field) {
        return column
      }

      return { ...column, width: newWidth }
    })

    setTableColumns(newColumns)
  }

  const onActivePageChange = (newPage: number) => {
    if (newPage === currentPage) {
      return
    }

    changeCurrentPage(newPage)
    tableContainerRef?.current?.scrollTo({ top: 0 })
  }

  return (
    <ResizeTableContextProvider>
      <Paper className={classes.paper}>
        {isLoading && (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        )}

        <TableContainer
          className={classes.container}
          ref={tableContainerRef}
          style={{
            overflowX: hideHorizontalScroll ? 'hidden' : 'auto'
          }}
        >
          <MuiTable
            aria-label="enhanced table"
            aria-labelledby="tableTitle"
            size="small"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {tableColumns.map((column) => (
                  <ResizeTableCell
                    align={column.align as TableCellProps['align']}
                    columnId={column.field}
                    key={column.field}
                    onDragEnd={hideScroll(false)}
                    onDragStart={hideScroll(true)}
                    onWidthChange={onWidthChange(column)}
                    style={{ position: 'sticky' }}
                    width={column.width}
                  >
                    {column.disableSorting ? (
                      column.displayName
                    ) : (
                      <TableSortLabel
                        active={sortData.field === column.field}
                        direction={sortData.direction}
                        onClick={onSortChange({
                          field: column.field,
                          direction: getNewSortDirection(
                            sortData.field === column.field,
                            sortData.direction
                          )
                        })}
                      >
                        {column.displayName}
                      </TableSortLabel>
                    )}
                  </ResizeTableCell>
                ))}
                <TableCell
                  component="th"
                  key="actions"
                  padding="normal"
                ></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {!isLoading && data?.length === 0 && (
                <TableRow>
                  <TableCell className={classes.emptyTableCell}>
                    <Box className={classes.notFoundContainer}>
                      <EmptyIllustration />
                      <H6>{'No data found'}</H6>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {data.map((row: Record<string, unknown>) => {
                return (
                  <TableRow
                    classes={{ root: classes.tableRowRoot }}
                    hover
                    key={row._id as string}
                    onClick={() => onRowClick(row)}
                    role="checkbox"
                  >
                    {columns?.map((column) => (
                      <TableCell
                        align={column.align as TableCellProps['align']}
                        key={column.field}
                        style={{
                          minWidth: column.width,
                          maxWidth: column.width,
                          overflow: 'hidden',
                          textOverflow:
                            column.field === '_tags' ? 'none' : 'ellipsis'
                        }}
                      >
                        {customRenderTableCell(column, row)}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              })}
            </TableBody>
          </MuiTable>
        </TableContainer>
      </Paper>

      <Box className={classes.paginationContainer}>
        <Pagination
          activePage={currentPage}
          itemsCount={dataCount}
          onActivePageChange={onActivePageChange}
          onPageSizeChange={onPageSizeChange}
          pageSize={currentPageSize}
          pageSizes={[10, 25, 50, 100]}
        />
      </Box>
    </ResizeTableContextProvider>
  )
}

export const MainTable = memo(Table)
