import { createContext, useContext } from 'react'

import _ from 'lodash'

import { PricingTier, PricingTierAssignment } from '~apis'
import {
  useQueryGetPricingTierAssignments,
  useQueryGetPricingTiers
} from '~services'

export interface PricingTierContext {
  pricingTierAssignments: PricingTierAssignment[]
  loadingPricingTierAssignments: boolean
  pricingTiers: PricingTier[]
  loadingPricingTiers: boolean
}

const PricingTiersContext = createContext<Partial<PricingTierContext>>({})

export const PricingTierContextProvider = (props: {
  children: JSX.Element
}) => {
  const pricingTierAssignmentsQueryResp = useQueryGetPricingTierAssignments()
  const pricingTiersQueryResp = useQueryGetPricingTiers()

  return (
    <PricingTiersContext.Provider
      value={{
        pricingTiers: _.chain(
          pricingTiersQueryResp?.data?.data?.pricing_tiers || []
        ).value(),
        loadingPricingTiers: pricingTiersQueryResp.isFetching,
        pricingTierAssignments: _.chain(
          pricingTierAssignmentsQueryResp?.data?.data?.assignments || []
        ).value(),
        loadingPricingTierAssignments:
          pricingTierAssignmentsQueryResp.isFetching
      }}
    >
      {props.children}
    </PricingTiersContext.Provider>
  )
}

export const usePricingTiers = () => useContext(PricingTiersContext)
