import { useState } from 'react'

import { useAuthenticator } from '@aws-amplify/ui-react'
import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  TextField
} from '@epilot/base-elements'
import Alert from '@material-ui/lab/Alert'
import _ from 'lodash'

import { PricingTier } from '~apis'
import { useMutationCreatePricingTier } from '~services'

import { PRICING_TIER_URL } from '../../routes/RouteUtils'
import { PricingTierContent } from '../organisms/PricingTierContent'

const useStyles = makeStyles({
  container: {
    marginTop: 20,
    width: '50%',
    '& > div:not(:first-child)': {
      marginTop: 20
    }
  }
})

export const CreatePricingTierPage = () => {
  const classes = useStyles()

  const [pricingTier, setPricingTier] = useState<PricingTier>({})
  const [errorMessage, setErrorMessage] = useState('')
  const { user } = useAuthenticator((context) => [context?.user])

  const createPricingTier = useMutationCreatePricingTier()

  const handleChangeName = (event) => {
    setPricingTier((prevState) => ({
      ...prevState,
      name: event.target.value
    }))
  }

  const handleCreatePricingTier = () => {
    if (_.isEmpty(pricingTier?.name?.trim())) {
      setErrorMessage('Pricing tier name is required')

      return
    }
    createPricingTier.mutate(
      {
        ...pricingTier,
        created_by: user?.attributes?.email,
        is_pure_360: true
      },
      {
        onSuccess: () => {
          setErrorMessage('')
          window.location.href = PRICING_TIER_URL
        },
        onError: (err) => {
          setErrorMessage(
            `Error while creating pricing tier. ${err?.['message']}`
          )
        }
      }
    )
  }

  return (
    <>
      <Box display="flex" flexDirection="row">
        <Box
          className={classes.container}
          display="flex"
          flexDirection="column"
        >
          <TextField
            error={
              !_.isEmpty(pricingTier) && _.isEmpty(pricingTier?.name?.trim())
            }
            helperText="Pricing tier name is required"
            label="Name*"
            onChange={handleChangeName}
            value={pricingTier?.name}
            variant="standard"
          />
        </Box>
      </Box>
      <Box width={'50%'}>
        <PricingTierContent
          editingPricingTier={pricingTier}
          expanded={true}
          isOverridden={false}
          setEditingPricingTier={setPricingTier}
        ></PricingTierContent>
      </Box>

      <Box>
        <Button
          color="secondary"
          disabled={createPricingTier.isLoading}
          onClick={handleCreatePricingTier}
          style={{ textTransform: 'none', marginTop: 20 }}
          variant="contained"
        >
          {createPricingTier.isLoading ? (
            <CircularProgress color={'inherit'} size={20} />
          ) : (
            'Create'
          )}
        </Button>
        {errorMessage && (
          <Alert
            onClose={() => setErrorMessage('')}
            severity="error"
            style={{ margin: '10px 0px' }}
          >
            {errorMessage}
          </Alert>
        )}
      </Box>
    </>
  )
}
