import { ChangeEvent } from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  LargeText,
  Switch,
  TextField
} from '@epilot/base-elements'
import _ from 'lodash'

export const FieldType = {
  SWITCH: 0,
  NUMBER_FIELD: 1,
  TEXT_FIELD: 2,
  DROPDOWN: 3
}

const PricingTierSettings = [
  {
    key: 'sso',
    label: 'Single Sign-On',
    group: 'Security',
    type: FieldType.SWITCH
  },
  {
    key: 'webhook',
    label: 'Webhooks',
    group: '3rd Party Solutions',
    type: FieldType.SWITCH
  },
  {
    key: 'customer_portal',
    label: 'Customer portal',
    type: FieldType.SWITCH
  },
  {
    key: 'installer_portal',
    label: 'Installer Portal',
    type: FieldType.SWITCH
  },
  {
    key: 'metering',
    label: 'Metering',
    type: FieldType.SWITCH
  },
  {
    key: 'legacy_craftsmen',
    label: 'Craftsmen directory',
    type: FieldType.SWITCH
  },
  {
    key: 'custom_design',
    label: 'Custom design',
    type: FieldType.SWITCH
  },
  {
    key: 'product_availability_service',
    label: 'Product availability service',
    type: FieldType.SWITCH
  },
  {
    key: 'tracking_in_journey',
    label: 'Tracking in Journey',
    type: FieldType.SWITCH
  },
  {
    key: 'trigger_automation_in_wf',
    label: 'Trigger automation in wf',
    type: FieldType.SWITCH
  },
  {
    key: 'share_journey',
    label: 'Share Journey',
    type: FieldType.SWITCH
  },
  {
    key: 'permissions_on_attribute_level_in_ecp',
    label: 'Permissions on attribute level in ECP',
    type: FieldType.SWITCH
  },
  {
    key: 'free_user',
    label: 'Free user',
    group: 'User',
    type: FieldType.NUMBER_FIELD
  },
  {
    key: 'active_groups',
    label: 'Active Groups',
    type: FieldType.NUMBER_FIELD
  },
  {
    key: 'custom_email_subdomain',
    label: 'Custom email domain',
    type: FieldType.NUMBER_FIELD
  },
  {
    key: 'designs',
    label: 'Designs',
    type: FieldType.NUMBER_FIELD
  },
  {
    key: 'roles',
    label: 'Roles',
    type: FieldType.NUMBER_FIELD
  },
  {
    key: 'active_journeys',
    label: 'Active Journeys',
    type: FieldType.NUMBER_FIELD
  },
  {
    key: 'workflows',
    label: 'Workflows',
    type: FieldType.NUMBER_FIELD
  },
  {
    key: 'active_workflows',
    label: 'Active Workflows',
    type: FieldType.NUMBER_FIELD
  },
  {
    key: 'contract_entity',
    label: 'Contract Entity',
    type: FieldType.NUMBER_FIELD
  }
]

export const PricingTierContent = ({
  editingPricingTier,
  setEditingPricingTier,
  expanded,
  isOverridden
}) => {
  const handleSettingQuotaChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value

      setEditingPricingTier((prevState) => {
        const newConfig = _.cloneDeep(prevState)
        const path = isOverridden
          ? `override_settings.${key}.quota`
          : `settings.${key}.quota`

        _.set(newConfig, path, +value)

        return newConfig
      })
    }

  const handleSettingEnabledChange = (key: string, isChecked: boolean) => {
    setEditingPricingTier((prevState) => {
      const newConfig = _.cloneDeep(prevState)
      const path = isOverridden
        ? `override_settings.${key}.enabled`
        : `settings.${key}.enabled`

      _.set(newConfig, path, isChecked)

      return newConfig
    })
  }

  const isShowGlobalSetting = (type: number, settingKey: string) => {
    switch (type) {
      case FieldType.NUMBER_FIELD:
        return (
          editingPricingTier?.override_settings &&
          editingPricingTier?.settings?.[settingKey] &&
          editingPricingTier?.override_settings?.[settingKey] &&
          editingPricingTier?.settings?.[settingKey]?.quota !=
            editingPricingTier?.override_settings?.[settingKey]?.quota
        )
      case FieldType.SWITCH:
        return (
          editingPricingTier?.override_settings &&
          editingPricingTier?.settings?.[settingKey] &&
          editingPricingTier?.override_settings?.[settingKey] &&
          editingPricingTier?.settings?.[settingKey]?.enabled !=
            editingPricingTier?.override_settings?.[settingKey]?.enabled
        )

      default:
        break
    }
  }
  const isEnabled = (settingKey: string) => {
    return editingPricingTier?.override_settings?.[settingKey]
      ? editingPricingTier?.override_settings?.[settingKey]?.enabled
      : editingPricingTier?.settings?.[settingKey]?.enabled || false
  }
  const getQuotaSetting = (settingKey: string) => {
    return editingPricingTier?.override_settings?.[settingKey]
      ? editingPricingTier?.override_settings?.[settingKey]?.quota
      : editingPricingTier?.settings?.[settingKey]?.quota || 0
  }

  return (
    <>
      <Box marginTop={'24px'}>
        <Accordion defaultExpanded={expanded}>
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            Pricing tier settings
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ width: '100%' }}>
              {PricingTierSettings.map((setting) => (
                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="row"
                  id={setting.key}
                  justifyContent="space-between"
                  key={setting.key}
                  marginLeft={'8px'}
                >
                  <LargeText key={`setting-${setting.key}`}>
                    {setting.label}{' '}
                    {isOverridden &&
                    isShowGlobalSetting(setting.type, setting.key)
                      ? `(Global setting: 
                      ${
                        setting.type == FieldType.SWITCH
                          ? editingPricingTier?.settings?.[setting.key]?.enabled
                          : editingPricingTier?.settings?.[setting.key]
                              ?.quota || 0
                      })`
                      : ''}
                  </LargeText>
                  {setting.type == FieldType.NUMBER_FIELD && (
                    <TextField
                      inputProps={{
                        min: 0
                      }}
                      key={`setting-quota-${setting.key}`}
                      onChange={handleSettingQuotaChange(setting.key)}
                      size="small"
                      style={{ margin: '4px', width: '90px' }}
                      type="number"
                      value={getQuotaSetting(setting.key)}
                      variant="outlined"
                    />
                  )}
                  {setting.type == FieldType.SWITCH && (
                    <Switch
                      checked={isEnabled(setting.key)}
                      key={`setting-enabled-${setting.key}`}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleSettingEnabledChange(
                          setting.key,
                          event.target.checked
                        )
                      }
                    />
                  )}
                </Box>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  )
}
