import { Box } from '@epilot/base-elements'

export const TabTableTemplate = ({ children }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent={'center'}
      marginTop={3}
      minHeight={'calc(100vh - 180px)'}
    >
      {children}
    </Box>
  )
}
