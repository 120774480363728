import { useCallback } from 'react'

import { capitalize } from 'lodash'
import { useSnackbar } from 'notistack'
import { useMutation, useQueryClient } from 'react-query'

import { Role } from '~apis'
import { ITableColumn } from '~common'
import { useRoleTable } from '~hooks'
import { useJSONEditorDrawer } from '~providers'

import { usePermissionClient } from '../../apis/permissions-client'
import { PERMISSIONS_QUERY_IDS } from '../../services'
import { MainTable } from '../molecules'

export const Roles = () => {
  const {
    isLoading,
    data: allRoles,
    dataCount,
    pagination,
    columns
  } = useRoleTable()

  const { enqueueSnackbar } = useSnackbar()

  const queryClient = useQueryClient()

  const permissionsClient = usePermissionClient()
  const saveRole = useMutation(
    ['saveRole'],
    (role: Role) =>
      permissionsClient.putRole({ roleId: role.id }, role, {
        headers: { 'x-ivy-org-id': role.organization_id }
      }),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(PERMISSIONS_QUERY_IDS.LIST_ALL_ROLES)

        enqueueSnackbar(`${res.status} Role saved successfully`, {
          variant: 'success'
        })
      },
      onError: (err) => {
        console.error('Failed saving role', err)
        enqueueSnackbar(`Failed saving role: ${err}`, { variant: 'error' })
      }
    }
  )

  const onSaveRole = useCallback(
    (role: Role) => {
      saveRole.mutate(role)
    },
    [saveRole]
  )

  const { toggleOpen, setJson, setTitle } = useJSONEditorDrawer({
    onSave: onSaveRole
  })

  const handleClickRoleChip = (role: Role) => {
    setJson(JSON.stringify(role, null, 2))
    setTitle(role.name)
    toggleOpen()
  }
  const renderTableCell = (
    column: ITableColumn,
    role: Role
  ): React.ReactNode => {
    let component: React.ReactNode

    switch (column?.field) {
      case 'type':
        component = role?.type
          .split('_')
          .map(capitalize)
          .join(' ') as React.ReactNode
        break
      default:
        component = role[column?.field] as React.ReactNode
        break
    }

    return component
  }

  return (
    <>
      <MainTable
        {...pagination}
        columns={columns}
        customRenderTableCell={renderTableCell}
        data={allRoles}
        dataCount={dataCount}
        isLoading={isLoading}
        locale={''}
        onRowClick={(row) => handleClickRoleChip(row as Role)}
      />
    </>
  )
}
