import React, { useEffect, useState } from 'react'

import { useAuthenticator } from '@aws-amplify/ui-react'
import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  TextField
} from '@epilot/base-elements'
import Alert from '@material-ui/lab/Alert'
import _ from 'lodash'

import { PricingTier } from '~apis'
import { formatDateTime } from '~common'
import { usePricingTiers } from '~providers'
import { useMutationUpdatePricingTier } from '~services'

import { InUseOrganizations } from './InUseOrganizations'
import { PricingTierContent } from './PricingTierContent'
import { RootRolePreview } from './RootRolePreview'

type Props = {
  isLoading: boolean
  originalPricingTier: PricingTier
  editingPricingTier: PricingTier
  setEditingPricingTier: React.Dispatch<React.SetStateAction<PricingTier>>
}

const useStyles = makeStyles({
  container: {
    marginTop: 20,
    width: '50%',
    '& > div:not(:first-child)': {
      marginTop: 30
    }
  }
})

export const PricingTierInfo: React.FC<Props> = ({
  isLoading,
  originalPricingTier,
  editingPricingTier,
  setEditingPricingTier
}) => {
  const classes = useStyles()
  const [isDisabledUpdateBtn, setIsDisabledUpdateBtn] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { pricingTierAssignments } = usePricingTiers()
  const { user } = useAuthenticator((context) => [context?.user])

  const updatePricingTier = useMutationUpdatePricingTier()

  const getPricingTierInUse = () => {
    return (
      pricingTierAssignments?.filter(
        (p) => p?.pricing_tier_id == originalPricingTier?.id
      )?.length || 0
    )
  }

  useEffect(() => {
    setIsDisabledUpdateBtn(_.isEqual(originalPricingTier, editingPricingTier))
  }, [originalPricingTier, editingPricingTier])

  const handleChangeName = (event) => {
    setEditingPricingTier((prevState) => ({
      ...prevState,
      name: event.target.value
    }))
  }

  const handleUpdatePricingTier = () => {
    updatePricingTier.mutate(
      { ...editingPricingTier, updated_by: user?.attributes?.email },
      {
        onError: (err) => {
          setErrorMessage(
            `Error while updating pricing tier. ${err?.['message']}`
          )
        }
      }
    )
  }

  return (
    <>
      {isLoading ? (
        <CircularProgress color="primary" size={20} />
      ) : (
        <>
          <Box display="flex" flexDirection="row">
            <Box
              className={classes.container}
              display="flex"
              flexDirection="column"
            >
              <TextField
                disabled
                label="Id"
                value={editingPricingTier?.id}
                variant="standard"
              />
              <TextField
                label="Name"
                onChange={handleChangeName}
                value={editingPricingTier?.name}
                variant="standard"
              />
              <TextField
                disabled
                label="Created Date"
                value={formatDateTime(editingPricingTier?.created_date)}
                variant="standard"
              />
              <TextField
                disabled
                label="Updated Date"
                value={formatDateTime(editingPricingTier?.updated_date)}
                variant="standard"
              />
              <PricingTierContent
                editingPricingTier={editingPricingTier}
                expanded={true}
                isOverridden={false}
                setEditingPricingTier={setEditingPricingTier}
              />
            </Box>
            <Box
              className={classes.container}
              display="flex"
              flexDirection="column"
              style={{ marginLeft: 20 }}
            >
              <TextField
                disabled
                label="Created by"
                value={editingPricingTier?.created_by}
                variant="standard"
              />
              <TextField
                disabled
                label="Updated By"
                value={editingPricingTier?.updated_by}
                variant="standard"
              />
              <TextField
                disabled
                label="In use"
                value={getPricingTierInUse()}
                variant="standard"
              />
              {getPricingTierInUse() > 0 && (
                <InUseOrganizations editingPricingTier={editingPricingTier} />
              )}

              <RootRolePreview pricingTier={editingPricingTier} />
            </Box>
          </Box>

          <Box>
            <Button
              color="secondary"
              disabled={isDisabledUpdateBtn || updatePricingTier.isLoading}
              onClick={handleUpdatePricingTier}
              style={{ textTransform: 'none', marginTop: 20 }}
              variant="contained"
            >
              {updatePricingTier.isLoading ? (
                <CircularProgress color={'inherit'} size={20} />
              ) : (
                'Update'
              )}
            </Button>
            {errorMessage && (
              <Alert
                onClose={() => setErrorMessage('')}
                severity="error"
                style={{ margin: '10px 0px' }}
              >
                {errorMessage}
              </Alert>
            )}
          </Box>
        </>
      )}
    </>
  )
}
