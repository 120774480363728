import { useQuery, UseQueryOptions } from 'react-query'

import { getEntityClient } from '~apis'

const client = getEntityClient()

enum ENTITY_QUERY_IDS {
  GET_ACTIVE_PARTNERS_BY_ORG_ID = 'GET_ACTIVE_PARTNERS_BY_ORG_ID',
  GET_ALL_ACTIVE_PARTNERS = 'GET_ALL_ACTIVE_PARTNERS',
  GET_ALL_PARTNERSHIP_BY_ORG_IDS = 'GET_ALL_PARTNERSHIP_BY_ORG_IDS',
  GET_ALL_USERS_BY_ORG_IDS = 'GET_ALL_USERS_BY_ORG_IDS'
}

export const useQueryUsersByOrgId = (
  orgId: string,
  portal: string,
  opts: UseQueryOptions<Awaited<ReturnType<typeof client.searchEntities>>> = {}
) => {
  return useQuery(
    [ENTITY_QUERY_IDS.GET_ALL_USERS_BY_ORG_IDS, orgId, portal],
    () =>
      client.searchEntities(
        null,
        {
          q: `_schema:portal_user AND origin:${portal}`,
          size: 10000
        },
        { headers: { 'x-ivy-org-id': orgId } }
      ),
    { refetchOnWindowFocus: false, ...opts }
  )
}

export const useQueryGetActivePartnerByOrgId = (
  orgId: string,
  opts: UseQueryOptions<Awaited<ReturnType<typeof client.searchEntities>>> = {}
) => {
  return useQuery(
    [ENTITY_QUERY_IDS.GET_ACTIVE_PARTNERS_BY_ORG_ID],
    () =>
      client.searchEntities(
        null,
        {
          q: '_schema:partner AND status:Partnership',
          size: 1000,
          fields: ['_id', '_title', 'partner_org_id']
        },
        { headers: { 'x-ivy-org-id': orgId } }
      ),
    { refetchOnWindowFocus: false, ...opts }
  )
}

export const useQueryGetAllActivePartners = (
  orgIds: string[],
  options?: UseQueryOptions<Awaited<ReturnType<typeof client.searchEntities>>[]>
) => {
  return useQuery(
    [ENTITY_QUERY_IDS.GET_ALL_PARTNERSHIP_BY_ORG_IDS, orgIds],
    () =>
      Promise.all(
        orgIds.map((orgId) =>
          client.searchEntities(
            null,
            {
              q: '_schema:partner AND status:Partnership',
              size: 1000,
              fields: ['_id', '_title', 'partner_org_id', '_org']
            },
            { headers: { 'x-ivy-org-id': orgId } }
          )
        )
      ),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: orgIds?.length > 0,
      ...options
    }
  )
}
