import { useEffect, useState } from 'react'

import { useAuthenticator } from '@aws-amplify/ui-react'
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Button,
  Toolbar,
  Typography,
  useTheme
} from '@epilot/base-elements'
import { CssBaseline, ListItem, useMediaQuery } from '@material-ui/core'
import {
  Business,
  BusinessCenter,
  ChevronLeft,
  Menu,
  Person
} from '@material-ui/icons'
import { clsx } from 'clsx'
import { Link, useHistory } from 'react-router-dom'
const navSettings = [
  {
    name: 'Organizations',
    icon: <Business />,
    url: '/organizations'
  },
  {
    name: 'Users',
    icon: <Person />,
    url: '/users'
  },
  {
    name: 'Pricing Tier',
    icon: <BusinessCenter />,
    url: '/pricing-tiers'
  }
]

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up('sm')]: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      flexShrink: 0
    }
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  content: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.up('sm')]: {
      marginLeft: -drawerWidth
    }
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  }
}))

export const MainTemplate = ({ children }) => {
  const { signOut, user } = useAuthenticator()

  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = useState(true)
  const [isMobileOpen, setIsMobileOpen] = useState(false)
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [activeUrl, setActiveUrl] = useState(null)
  const history = useHistory()

  const handleSmallScreenDrawerToggle = () => {
    setIsMobileOpen(!isMobileOpen)
  }

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (!activeUrl) {
      setActiveUrl(history.location.pathname)
    }
  }, [activeUrl, history.location])

  const drawerItem = (
    <div>
      <List>
        {navSettings.map((item) => (
          <ListItem
            component={Link}
            key={item.name}
            onClick={() => setActiveUrl(item.url)}
            style={{
              backgroundColor: activeUrl === item.url ? '#039BE5' : 'white'
            }}
            to={item.url}
          >
            <ListItemIcon
              style={{
                color: activeUrl === item.url ? 'white' : 'inherit'
              }}
            >
              {item.icon}
            </ListItemIcon>
            <ListItemText
              primary={item.name}
              style={{
                color: activeUrl === item.url ? 'white' : 'inherit'
              }}
            />
          </ListItem>
        ))}
      </List>
    </div>
  )

  useEffect(() => {
    setOpen(!isSmallScreen)
  }, [isSmallScreen])

  return (
    <Box display={'flex'} minHeight={'100vh'}>
      <CssBaseline />
      <AppBar
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            className={clsx(classes.menuButton, open && classes.hide)}
            color="inherit"
            edge="start"
            onClick={
              isSmallScreen ? handleSmallScreenDrawerToggle : handleDrawerOpen
            }
          >
            <Menu />
          </IconButton>
          <Typography noWrap variant="h6">
            {navSettings.find((elem) => elem.url === activeUrl)?.name || ''}
          </Typography>

          {user && (
            <span style={{ marginLeft: 'auto', marginRight: -12 }}>
              <Button
                color="inherit"
                onClick={() => {
                  signOut(() => history.push('/'))
                }}
              >
                Sign Out
              </Button>
            </span>
          )}
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Hidden implementation="css" smUp>
          <Drawer
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            classes={{
              paper: classes.drawerPaper
            }}
            onClose={handleSmallScreenDrawerToggle}
            open={isMobileOpen}
            variant="temporary"
          >
            {drawerItem}
          </Drawer>
        </Hidden>
        <Hidden implementation="css" xsDown>
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            open={open}
            variant="persistent"
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeft />
              </IconButton>
            </div>
            <Divider />
            {drawerItem}
          </Drawer>
        </Hidden>
      </nav>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open
        })}
      >
        <Box display={'flex'} flex={1} flexDirection={'column'} height={'100%'}>
          <Box className={classes.drawerHeader} />
          <Box display={'flex'} flexDirection={'column'} flexGrow={1}>
            {children}
          </Box>
        </Box>
      </main>
    </Box>
  )
}
