export enum TABS {
  SELECT_FROM_CURRENT_ENTITY_FILES = 'SELECT_FROM_CURRENT_ENTITY_FILES',
  SELECT_FROM_ALL_FILES = 'SELECT_FROM_ALL_FILES',
  UPLOAD = 'UPLOAD'
}

export enum SortDirection {
  ASCENDING = 'asc',
  DESCENDING = 'desc'
}
