import { useMutation, useQuery, UseQueryOptions } from 'react-query'

import { getUserClient } from '~apis'
import { IUser } from '~common'

const userClient = getUserClient()

const USER_QUERY_IDS = {
  GET_USER_BY_ID: 'GET_USER_BY_ID',
  GET_USER_TAGS: 'GET_USER_TAGS',
  GET_USERS: 'GET_USERS',
  GET_ORGANIZATION_USER: 'GET_ORGANIZATION_USER',
  GET_USER_DATA_POINTS: 'GET_USER_DATA_POINTS'
}

export const useQueryGetOrgUsers = (
  orgId: string,
  opts: UseQueryOptions<Awaited<ReturnType<typeof userClient.listUsersV2>>> = {}
) => {
  return useQuery(
    [USER_QUERY_IDS.GET_ORGANIZATION_USER, orgId],
    () =>
      userClient.listUsersV2(
        { limit: 1000 },
        {},
        {
          headers: {
            'x-ivy-org-id': orgId
          }
        }
      ),
    { refetchOnWindowFocus: true, ...opts }
  )
}

export const useQueryGetUserById = (
  userId: string,
  orgId: string,
  opts: UseQueryOptions<Awaited<ReturnType<typeof userClient.getUserV2>>> = {}
) => {
  return useQuery(
    [USER_QUERY_IDS.GET_USER_BY_ID, userId],
    () =>
      userClient.getUserV2(
        { id: userId },
        {},
        {
          headers: {
            'x-ivy-org-id': orgId
          }
        }
      ),
    { refetchOnWindowFocus: false, retry: false, ...opts }
  )
}

export const useQueryGetUserTags = (
  opts: UseQueryOptions<Awaited<ReturnType<typeof userClient.getUserTags>>> = {}
) => {
  return useQuery(
    [USER_QUERY_IDS.GET_USER_TAGS],
    () => userClient.getUserTags(),
    { refetchOnWindowFocus: false, retry: false, ...opts }
  )
}

export const useQueryGetUsers = (
  opts?: UseQueryOptions<Awaited<ReturnType<typeof userClient.getUsers>>>
) => {
  return useQuery(
    [USER_QUERY_IDS.GET_USERS],
    () => userClient.getUsers({ limit: '5000' }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      retry: false,
      ...opts
    }
  )
}

export const useQueryGetUserDataPoints = (
  opts?: UseQueryOptions<Awaited<ReturnType<typeof userClient.getDataPoints>>>
) => {
  return useQuery(
    [USER_QUERY_IDS.GET_USER_DATA_POINTS],
    () => userClient.getDataPoints(),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      retry: false,
      ...opts
    }
  )
}

export const useMutationUpdateUser = (orgId: string) => {
  return useMutation((user: IUser) => {
    return userClient.updateUserV2(user.id, user, {
      headers: {
        'x-ivy-org-id': orgId
      }
    })
  })
}
