import { useMemo } from 'react'

import { getClient, Components } from '@epilot/pricing-tier-client'

import { URL_CONFIG } from '~configs'

import { configureClient } from './utils.client'

export const getPricingTierClient = () => {
  const client = getClient()

  return configureClient(client, { baseURL: URL_CONFIG.PRICING_TIER })
}

export const usePricingTierClient = () =>
  useMemo(() => getPricingTierClient(), [])

export type PricingTier = Components.Schemas.PricingTier
export type PricingTierAssignment = Components.Schemas.Assignment
