import { useState } from 'react'

import {
  Box,
  createStyles,
  makeStyles,
  PrimaryTabs,
  Tab,
  TabContext,
  TabPanel
} from '@epilot/base-elements'
import { useParams } from 'react-router'

import { IOrganization } from '~common'
import { useDataPoints } from '~providers'
import { useQueryGetOrganization } from '~services'

import { OrganizationInfo, Partners, Roles } from '../../organisms'
import { TabTableTemplate } from '../../templates'
import { UserListPage } from '../UserListPage'

export enum TABS {
  ORGANIZATION_DETAILS = 'ORGANIZATION_DETAILS',
  USERS = 'USERS',
  ROLES = 'ROLES',
  PARTNERS = 'PARTNERS'
}

const useStyles = makeStyles(() =>
  createStyles({
    tabPanel: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1
    },
    input: {
      height: 44
    },
    filterTitle: {
      fontWeight: 600,
      fontSize: 16,
      color: '#152B42'
    },
    filterContainer: {
      padding: 24,
      backgroundColor: '#ffffff',
      marginTop: 24,
      borderRadius: 4,
      border: '1px solid #C5D0DB'
    },
    badge: {
      position: 'relative',
      top: 'unset',
      right: 'unset',
      transform: 'unset',
      transformOrigin: 'unset',
      marginLeft: 8
    }
  })
)

export type Action = 'create' | 'edit' | ''
export type OwnerInfo = {
  full_name?: string
  email_address?: string
}

export const UpdateOrganizationPage = () => {
  const classes = useStyles()
  const [currentTab, setCurrentTab] = useState<TABS>(TABS.ORGANIZATION_DETAILS)
  const handleTabChange = (_, newValue: TABS) => {
    setCurrentTab(newValue)
  }

  const [organization, setOrganization] = useState<IOrganization>(null)

  const { id } = useParams<{
    id: string
  }>()

  useQueryGetOrganization(
    {
      orgId: id
    },
    {
      enabled: Boolean(id),
      onSettled(data, error) {
        if (error) return
        setOrganization(data?.data as IOrganization)
      }
    }
  )

  const { mergedDataPointMap, loading: loadingDataPoint } = useDataPoints()
  const dataPoint = mergedDataPointMap[id]

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      style={{ height: '100%' }}
    >
      <TabContext value={currentTab}>
        <PrimaryTabs
          aria-label="simple tabs example"
          indicatorColor="primary"
          onChange={handleTabChange as never}
        >
          <Tab
            label={'Organization Details'}
            value={TABS.ORGANIZATION_DETAILS}
          />
          <Tab label={'Users'} value={TABS.USERS} />
          <Tab label={'Roles'} value={TABS.ROLES} />
          <Tab label={'Active Partners'} value={TABS.PARTNERS} />
        </PrimaryTabs>
        <TabPanel
          className={
            currentTab === TABS.ORGANIZATION_DETAILS
              ? classes.tabPanel
              : undefined
          }
          value={TABS.ORGANIZATION_DETAILS}
        >
          <Box display="flex" flexDirection="column" py={3}>
            <OrganizationInfo
              dataPoint={dataPoint}
              loadingDataPoint={loadingDataPoint}
              mergedDataPointMap={mergedDataPointMap}
              orgInfo={organization}
              setOrganization={setOrganization}
            />
          </Box>
        </TabPanel>
        <TabPanel
          className={currentTab === TABS.USERS ? classes.tabPanel : undefined}
          value={TABS.USERS}
        >
          <TabTableTemplate>
            <UserListPage
              organizationId={organization?.id}
              showActionBar={false}
            />
          </TabTableTemplate>
        </TabPanel>
        <TabPanel
          className={currentTab === TABS.ROLES ? classes.tabPanel : undefined}
          value={TABS.ROLES}
        >
          <TabTableTemplate>
            <Roles />
          </TabTableTemplate>
        </TabPanel>
        <TabPanel
          className={
            currentTab === TABS.PARTNERS ? classes.tabPanel : undefined
          }
          value={TABS.PARTNERS}
        >
          <TabTableTemplate>
            <Partners />
          </TabTableTemplate>
        </TabPanel>
      </TabContext>
    </Box>
  )
}
