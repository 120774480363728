import { useMemo } from 'react'

import { parse } from 'query-string'
import { useLocation } from 'react-router'

export function useQueryParams<T>() {
  const { search } = useLocation()

  return useMemo(() => parse(search) as T, [search])
}
