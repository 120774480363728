import { useCallback, useEffect, useState } from 'react'

import orderBy from 'lodash/orderBy'
import { useParams } from 'react-router'
import { useUpdateEffect } from 'react-use'

import { EntityItem } from '~apis'
import { ITableColumn } from '~common'
import { useQueryGetActivePartnerByOrgId } from '~services'

import { useTableConfig } from './useTableConfig'

const columns: ITableColumn[] = [
  {
    field: 'partner_org_id',
    displayName: 'Id',
    align: 'left',
    width: 200
  },
  {
    field: '_title',
    displayName: 'Name',
    align: 'left',
    width: 200
  },
  {
    field: 'actual_users',
    displayName: 'Actual users',
    align: 'left',
    width: 200,
    disableSorting: true
  },
  {
    field: 'max_users',
    displayName: 'Max users last months',
    align: 'left',
    width: 200,
    disableSorting: true
  }
]

export const usePartnerTable = () => {
  const [partners, setPartners] = useState<EntityItem[]>([])
  const [filteredPartners, setFilteredPartners] = useState<EntityItem[]>([])
  const [paginatedPartners, setPaginatedPartners] = useState<EntityItem[]>([])

  const { id } = useParams<{
    id: string
  }>()

  const { isLoading, data } = useQueryGetActivePartnerByOrgId(id)

  useEffect(() => {
    if (data?.data?.results) {
      setPartners(data?.data?.results)
      setFilteredPartners(data?.data?.results)
    }
  }, [data?.data?.results])

  const {
    data: { pageSize, page, sort },
    actions: { changePage, changePageSize, changeSort }
  } = useTableConfig('admin-portal-partners')

  // sort partner table column
  useUpdateEffect(() => {
    if (sort.split(':')?.length > 0) {
      type SortOrder = boolean | 'asc' | 'desc'
      const [field, order] = sort.split(':')

      setFilteredPartners((prevState) => {
        const sortedPartners = orderBy(
          prevState.map((elem) => ({
            ...elem,
            id: Number(elem.id)
          })),
          [field],
          [order as SortOrder]
        )

        return sortedPartners.map((elem) => ({ ...elem, id: String(elem.id) }))
      })
    }
  }, [sort])

  useUpdateEffect(() => {
    setPaginatedPartners(
      filteredPartners.slice((page - 1) * pageSize, page * pageSize)
    )
  }, [pageSize, page])

  useUpdateEffect(() => {
    setPaginatedPartners(filteredPartners.slice(0, pageSize))
    changeCurrentPage(1)
  }, [filteredPartners])

  const changeCurrentPage = useCallback(
    (newPage: number) => {
      changePage(newPage)
    },
    [changePage]
  )

  const changeCurrentPageSize = useCallback(
    (pageSize: number) => {
      changePageSize(pageSize)
    },
    [changePageSize]
  )

  const changeCurrentSort = useCallback(
    (sort: string) => {
      changeSort(sort)
    },
    [changeSort]
  )

  return {
    data: paginatedPartners,
    dataCount: partners?.length,
    isLoading: isLoading,
    columns,
    pagination: {
      currentPage: page,
      currentPageSize: pageSize,
      currentSort: sort,
      changeCurrentPage,
      changeCurrentPageSize,
      changeCurrentSort
    }
  }
}
