import { useState } from 'react'

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  IconButton,
  InputAdornment,
  TextField
} from '@epilot/base-elements'
import Alert from '@material-ui/lab/Alert'
import { Link, useHistory } from 'react-router-dom'

import { formatDateTime, ITableColumn } from '~common'
import { usePricingTierTable } from '~hooks'
import { usePricingTiers } from '~providers'

import { PricingTier } from '../../apis/pricing-tier-client'
import { useMutationDeletePricingTier } from '../../services/pricing-tier.service'
import { MainTable } from '../molecules'

export const PricingTierListPage = () => {
  const {
    isLoading,
    data: paginatedPricingTiers,
    dataCount,
    pagination,
    columns,
    searchQuery,
    setSearchQuery
  } = usePricingTierTable()

  const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [selectedPricingTier, setSelectedPricingTier] =
    useState<PricingTier>(null)
  const { loadingPricingTierAssignments, pricingTierAssignments } =
    usePricingTiers()
  const deletePricingTier = useMutationDeletePricingTier()
  const history = useHistory()

  const handleClickOpen = (
    event: React.MouseEvent,
    pricingTier: PricingTier
  ) => {
    event.stopPropagation()
    event.preventDefault()
    setSelectedPricingTier(pricingTier)
    setIsShowDeleteDialog(true)
  }

  const handleClose = () => {
    setIsShowDeleteDialog(false)
  }

  const handleConfirmDeletePricingTier = () => {
    deletePricingTier.mutate(selectedPricingTier?.id, {
      onSuccess: () => {
        setIsShowDeleteDialog(false)
        setErrorMessage('')
        setSelectedPricingTier(null)
      },
      onError: (err) => {
        setErrorMessage(
          `Error while deleting pricing tier. ${err?.['message']}`
        )
      }
    })
  }

  const customDeleteColumn = (pricingTier: PricingTier) => {
    const isBeingUsed =
      pricingTierAssignments?.filter(
        (p) => p?.pricing_tier_id == pricingTier?.id
      )?.length > 0

    return (
      <IconButton
        disabled={!!isBeingUsed}
        onClick={(event) => handleClickOpen(event, pricingTier)}
        style={{ marginLeft: 16 }}
      >
        <Icon name="delete" />
      </IconButton>
    )
  }

  const renderTableCell = (
    column: ITableColumn,
    pricingTier: PricingTier
  ): React.ReactNode => {
    let component: React.ReactNode

    switch (column?.field) {
      case 'created_date':
        component = formatDateTime(
          pricingTier.created_date,
          'en'
        ) as React.ReactNode
        break

      case 'updated_date':
        component = formatDateTime(
          pricingTier.updated_date,
          'en'
        ) as React.ReactNode
        break

      case 'in_use':
        component = loadingPricingTierAssignments ? (
          <CircularProgress color="primary" size={20} />
        ) : (
          pricingTierAssignments.filter(
            (p) => p?.pricing_tier_id == pricingTier?.id
          ).length
        )

        break

      case 'delete':
        component = customDeleteColumn(pricingTier)

        break

      default:
        component = pricingTier[column?.field] as React.ReactNode
        break
    }

    return component
  }

  const onRowClick = (row: PricingTier) => {
    history.push(`/pricing-tiers/${row?.id}`)
  }

  return (
    <>
      <Box
        alignItems="center"
        display={'flex'}
        justifyContent={'space-between'}
        mb={2}
      >
        <Box display={'flex'} width="50%">
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon color="secondary" name="search" />
                </InputAdornment>
              )
            }}
            fullWidth
            label="Search"
            onChange={(event) => setSearchQuery(event?.target?.value)}
            value={searchQuery}
          />
        </Box>
        <Box display={'flex'} flexDirection="row">
          <Button
            color="primary"
            component={Link}
            style={{ marginRight: 8 }}
            to={'/pricing-tiers/create'}
            variant="contained"
          >
            Create
          </Button>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" height={'100%'} width={'100%'}>
        <MainTable
          {...pagination}
          columns={columns}
          customRenderTableCell={renderTableCell}
          data={paginatedPricingTiers}
          dataCount={dataCount}
          isLoading={isLoading}
          locale={''}
          onRowClick={onRowClick}
        />
      </Box>
      <>
        <Dialog
          aria-describedby="dialog-description"
          aria-labelledby="dialog-title"
          maxWidth="xs"
          onClose={handleClose}
          open={isShowDeleteDialog}
        >
          <DialogTitle id="dialog-title">Delete pricing tier</DialogTitle>
          <DialogContent>
            <DialogContentText id="dialog-description">
              Do you want to delete pricing tier named{' '}
              <b>{selectedPricingTier?.name}</b>?
            </DialogContentText>
            {errorMessage && (
              <Alert
                onClose={() => setErrorMessage('')}
                severity="error"
                style={{ padding: '0px 16px' }}
              >
                {errorMessage}
              </Alert>
            )}
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleClose} variant="outlined">
              Cancel
            </Button>

            <Button
              color="primary"
              onClick={handleConfirmDeletePricingTier}
              variant="contained"
            >
              {deletePricingTier.isLoading ? (
                <CircularProgress color="primary" size={20} />
              ) : (
                'Confirm'
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </>
  )
}
