import React, { useRef } from 'react'

import {
  BodyText,
  Box,
  Button,
  Chip,
  CircularProgress,
  InlineSpacing,
  Link
} from '@epilot/base-elements'
import { map, uniqueId } from 'lodash'
import { CSVLink } from 'react-csv'
import { useHistory } from 'react-router'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'

import { Role } from '~apis'
import { formatDateTime, ITableColumn, IUser } from '~common'
import { useUserTable } from '~hooks'
import {
  useJSONEditorDrawer,
  usePricingTiers,
  useRoleAndPermission
} from '~providers'
import { useQueryGetOrganizations } from '~services'

import { StatusChip } from '../atoms'
import { MainTable } from '../molecules'
import { TemplateSearchBar } from '../organisms'

export interface Assignment {
  userId: string
}

type Props = {
  showActionBar?: boolean
  organizationId?: string
}

export const UserListPage: React.FC<Props> = ({
  showActionBar = true,
  organizationId = ''
}) => {
  const {
    isLoading,
    data: paginatedUsers,
    originalData: users,
    dataCount,
    pagination,
    columns,
    suggestions,
    fieldValuesMap,
    setSearchAndFilterValues
  } = useUserTable(organizationId)

  const history = useHistory()

  const csvLinkRef = useRef(null)
  const { data: orgsQueryResp, isFetching: isFetchingOrgs } =
    useQueryGetOrganizations()

  const {
    setGetAllRolesOrgIds,
    setGetAssignedRoleUsers,
    userIdAssignedRoleIdsMap,
    rolesMap,
    loadingGetAllRolesOrgIds,
    loadingGetAssignedRoleUsers,
    allAssignmentsAllOrgs,
    allRolesAllOrgs,
    isReadyExport
  } = useRoleAndPermission()

  const {
    loadingPricingTiers,
    pricingTiers,
    loadingPricingTierAssignments,
    pricingTierAssignments
  } = usePricingTiers()

  const mapAllAssignmentsAllOrgs = map(
    allAssignmentsAllOrgs,
    (item) => item.assignments
  )
    .flat()
    .reduce((acc, cur) => {
      acc[(cur as Assignment)?.userId] = cur.roles

      return acc
    }, {})

  const mapAllRolesAllOrgs = map(allRolesAllOrgs, (item) => item.roles)
    .flat()
    .reduce((acc, cur) => {
      acc[cur.id] = cur.name

      return acc
    }, {})

  const handleExportCSV = () => {
    csvLinkRef.current?.link.click()
  }

  const onRowClick = (row: IUser) => {
    history.push(`/users/${row?.id}?orgId=${row.organization_id}`)
  }

  useUpdateEffect(() => {
    setGetAllRolesOrgIds(paginatedUsers.map((user) => user.organization_id))
    setGetAssignedRoleUsers(paginatedUsers)
  }, [paginatedUsers])

  const { toggleOpen, setJson, setTitle } = useJSONEditorDrawer()

  const handleClickRoleChip =
    (role: Role) =>
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.stopPropagation()
      event.preventDefault()
      setJson(JSON.stringify(role, null, 2))
      setTitle(role.name)
      toggleOpen()
    }
  const handleClickOrganization =
    (organization_id: string) =>
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.stopPropagation()
      event.preventDefault()
      history.push(`/organizations/${organization_id}`)
    }

  const renderTableCell = (
    column: ITableColumn,
    user: IUser
  ): React.ReactNode => {
    let component: React.ReactNode
    const userAssignedRoleIds = userIdAssignedRoleIdsMap[user.id] ?? []

    switch (column?.field) {
      case 'created_at':
        component = formatDateTime(user.created_at, 'en') as React.ReactNode
        break
      case 'status':
        component = user?.status ? (
          <Box display="flex">
            <StatusChip status={user?.status as string} />
          </Box>
        ) : null
        break
      case 'tags':
        component = user?.tags ? (
          <Tags color="primary">
            {(user?.tags as string[])?.map((tag) => (
              <InlineSpacing key={uniqueId('portal')} scale={1}>
                <Chip key={uniqueId('portal')} label={tag} />
              </InlineSpacing>
            ))}
          </Tags>
        ) : null
        break
      case '360_roles':
        component =
          loadingGetAllRolesOrgIds || loadingGetAssignedRoleUsers ? (
            <CircularProgress color="primary" size={20} />
          ) : (
            <RoleAssignments color="primary">
              {userAssignedRoleIds.map((roleId) => {
                const role = rolesMap[roleId]

                return (
                  <StyledLink
                    key={role?.id}
                    onClick={handleClickRoleChip(role)}
                  >
                    {role?.name}
                  </StyledLink>
                )
              })}
            </RoleAssignments>
          )
        break
      case 'organization':
        component = isFetchingOrgs ? (
          <CircularProgress color="primary" size={20} />
        ) : (
          <StyledLink onClick={handleClickOrganization(user?.organization_id)}>
            {user?.organization?.name}
          </StyledLink>
        )
        break
      case 'customer_number':
        component = isFetchingOrgs ? (
          <CircularProgress color="primary" size={20} />
        ) : (
          user?.organization?.['customer_number']
        )
        break
      case 'pricing_tier':
        component =
          loadingPricingTierAssignments || loadingPricingTiers ? (
            <CircularProgress color="primary" size={20} />
          ) : (
            user.pricing_tier
          )
        break
      default:
        component = user[column?.field] as React.ReactNode
        break
    }

    return component
  }

  return (
    <>
      {showActionBar ? (
        <>
          <Box
            alignItems="center"
            display={'flex'}
            justifyContent={'space-between'}
            mb={2}
          >
            <Box display={'flex'} width="50%">
              <TemplateSearchBar
                fieldValuesMap={fieldValuesMap}
                onChange={setSearchAndFilterValues}
                suggestions={suggestions}
              />
            </Box>
            <Button
              color="primary"
              disabled={isLoading || !isReadyExport}
              onClick={handleExportCSV}
              variant="contained"
            >
              Export
            </Button>
          </Box>
          <CSVLink
            className="hidden"
            data={users.map((user) => {
              let tmpUsers = {}

              columns.forEach((column) => {
                if (column.field === '360_roles') {
                  const userRoles = mapAllAssignmentsAllOrgs?.[user.id]
                    ?.map((role) =>
                      role?.endsWith(':owner')
                        ? 'Owner'
                        : mapAllRolesAllOrgs?.[role] || role
                    )
                    .join(',')

                  tmpUsers = {
                    ...tmpUsers,
                    [column.field]: userRoles
                  }
                } else if (column.field === 'organization') {
                  tmpUsers = {
                    ...tmpUsers,
                    [column.field]: orgsQueryResp?.data?.organizations?.find(
                      (org) => org?.id === user?.organization_id
                    )?.name
                  }
                } else if (column.field === 'customer_number') {
                  tmpUsers = {
                    ...tmpUsers,
                    [column.field]: orgsQueryResp?.data?.organizations?.find(
                      (org) => org?.id === user?.organization_id
                    )?.['customer_number']
                  }
                } else if (column.field === 'pricing_tier') {
                  tmpUsers = {
                    ...tmpUsers,
                    [column.field]: pricingTiers?.find(
                      (pricingTier) =>
                        pricingTier?.id ==
                        pricingTierAssignments?.find(
                          (pricingTier) =>
                            pricingTier?.organization_id ==
                            user?.organization_id
                        )?.pricing_tier_id
                    )?.name
                  }
                } else {
                  tmpUsers = {
                    ...tmpUsers,
                    [column.field]: user[column.field]
                  }
                }
              })

              return tmpUsers
            })}
            filename={`users-${new Date().toISOString()}.csv`}
            ref={csvLinkRef}
            target="_blank"
          />
        </>
      ) : null}

      <Box display="flex" flexDirection="column" height={'100%'} width={'100%'}>
        <MainTable
          {...pagination}
          columns={columns}
          customRenderTableCell={renderTableCell}
          data={paginatedUsers}
          dataCount={dataCount}
          isLoading={isLoading}
          locale={''}
          onRowClick={onRowClick}
        />
      </Box>
    </>
  )
}

const RoleAssignments = styled(BodyText)`
  > a {
    margin-right: 0;
    padding-right: 0;
  }

  > a + a:before {
    display: inline-block;
    content: ', ';
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  margin-right: 4px;
`

const Tags = styled(BodyText)`
  > a {
    margin-right: 0;
    padding-right: 0;
  }

  > a + a:before {
    display: inline-block;
    content: ', ';
  }
`
