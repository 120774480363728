import { useCallback, useEffect, useState } from 'react'

import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import { useUpdateEffect } from 'react-use'

import {
  ITableColumn,
  IUser,
  parseFieldValuesSuggestionMap,
  parseSearchAndFilterSuggestion,
  SearchSuggestion
} from '~common'
import { usePricingTiers } from '~providers'
import { useQueryGetOrganizations, useQueryGetUsers } from '~services'

import { useTableConfig } from './useTableConfig'

export const UserTableColumns: ITableColumn[] = [
  {
    field: 'id',
    displayName: 'Id',
    align: 'left',
    width: 200,
    enableFiltering: true
  },
  {
    field: 'display_name',
    displayName: 'Name',
    align: 'left',
    width: 200,
    enableFiltering: true
  },
  {
    field: 'email',
    displayName: 'Email address',
    align: 'left',
    width: 200,
    enableFiltering: true
  },
  {
    field: 'status',
    displayName: 'Status',
    align: 'left',
    width: 200,
    enableFiltering: true
  },
  {
    field: 'organization',
    displayName: 'Organization',
    align: 'left',
    width: 200,
    disableSorting: true,
    enableFiltering: true
  },
  {
    field: 'pricing_tier',
    displayName: 'Pricing tier',
    align: 'left',
    width: 200,
    disableSorting: true,
    enableFiltering: true
  },
  {
    field: 'customer_number',
    displayName: 'Customer number',
    align: 'left',
    width: 200
  },
  {
    field: '360_roles',
    displayName: '360 Roles',
    align: 'left',
    width: 200,
    disableSorting: true
  },
  {
    field: 'tags',
    displayName: 'Tags',
    align: 'left',
    width: 200,
    enableFiltering: true
  },
  {
    field: 'created_at',
    displayName: 'Created date',
    align: 'left',
    width: 200
  }
]

const UserSuggestions: SearchSuggestion[] = UserTableColumns.filter(
  (column) => column.enableFiltering
).map((column) => {
  return {
    type: 'filter',
    label: `Filter: ${column.displayName}`,
    append: `${column.field}:`
  }
})

export const useUserTable = (orgId?: string) => {
  const [users, setUsers] = useState<IUser[]>([])
  const [filteredUsers, setFilteredUsers] = useState<IUser[]>([])
  const [paginatedUsers, setPaginatedUsers] = useState<IUser[]>([])

  const [searchAndFilterValues, setSearchAndFilterValues] = useState<
    SearchSuggestion[]
  >([])

  const [fieldValuesMap, setFieldValuesMap] = useState<
    Record<string, string[]>
  >({})

  const {
    data: orgsQueryResp,
    isFetched: isFetchedOrgs,
    isFetching: isFetchingOrgs
  } = useQueryGetOrganizations()

  const { isFetching, data, isFetched: isFetchedUsers } = useQueryGetUsers()

  const {
    loadingPricingTiers,
    pricingTiers,
    loadingPricingTierAssignments,
    pricingTierAssignments
  } = usePricingTiers()

  // init and combine user data with pricing tier and organization
  useEffect(() => {
    if (
      isFetchedUsers &&
      isFetchedOrgs &&
      !loadingPricingTiers &&
      !loadingPricingTierAssignments
    ) {
      let users =
        (filter(
          (data?.data as unknown as Record<string, IUser[]>)?.users,
          (user: IUser) => user && user.id
        ) as IUser[]) || []

      users = users.map((user) => {
        const pricingTier = pricingTiers.find(
          (pricingTier) =>
            pricingTier?.id ==
            pricingTierAssignments?.find(
              (pricingTier) =>
                pricingTier?.organization_id == user?.organization_id
            )?.pricing_tier_id
        )

        return {
          ...user,
          organization:
            orgsQueryResp.data.organizations.find(
              (org) => org?.id === user?.organization_id
            ) ?? null,
          pricing_tier: pricingTier?.name
        }
      })

      if (orgId) {
        users = users.filter((user) => user.organization_id === orgId)
      }

      setFilteredUsers(users)
      setUsers(users)
    }
  }, [
    data,
    isFetchedUsers,
    isFetchedOrgs,
    orgsQueryResp,
    loadingPricingTiers,
    loadingPricingTierAssignments,
    pricingTiers,
    pricingTierAssignments,
    orgId
  ])

  const {
    data: { pageSize, page, sort },
    actions: { changePage, changePageSize, changeSort }
  } = useTableConfig('admin-portal-users')

  useUpdateEffect(() => {
    const enableFilteringFields = UserTableColumns.filter(
      (column) => column.enableFiltering
    ).map((column) => column.field)

    setFieldValuesMap(
      parseFieldValuesSuggestionMap(users, enableFilteringFields)
    )
  }, [users])

  useUpdateEffect(() => {
    const { filterKeyValueMap, searchValue } = parseSearchAndFilterSuggestion(
      searchAndFilterValues
    )
    let filteredUsers = cloneDeep(users)

    if (!isEmpty(filterKeyValueMap)) {
      filteredUsers = users.filter((user) =>
        Object.entries(filterKeyValueMap).every(([key, value]) => {
          switch (key) {
            case 'organization':
              return user[key]?.name === value
            default:
              if (isArray(user[key])) {
                return user[key].includes(value)
              }

              return user[key] === value
          }
        })
      )
    }

    if (searchValue) {
      filteredUsers = filteredUsers.filter(
        (user) =>
          user.id?.toString() === searchValue ||
          user.display_name
            ?.toLowerCase()
            ?.includes(searchValue?.toLowerCase()) ||
          user?.email?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
          user?.status?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
          user?.organization_id?.toString === searchValue
      )
    }

    setFilteredUsers(filteredUsers)
  }, [searchAndFilterValues])

  // sort organization table column
  useUpdateEffect(() => {
    if (sort.split(':')?.length > 0) {
      type SortOrder = boolean | 'asc' | 'desc'
      const [field, order] = sort.split(':')

      setFilteredUsers((prevState) => {
        const sortedUsers = orderBy(
          prevState.map((elem) => ({
            ...elem,
            id: Number(elem.id)
          })),
          [field],
          [order as SortOrder]
        )

        return sortedUsers.map((elem) => ({ ...elem, id: String(elem.id) }))
      })
    }
  }, [sort])

  // new search results, reset current page to 1
  useUpdateEffect(() => {
    setPaginatedUsers(filteredUsers.slice(0, pageSize))
    changeCurrentPage(1)
  }, [filteredUsers])

  // normal pagination action
  useUpdateEffect(() => {
    setPaginatedUsers(
      filteredUsers.slice((page - 1) * pageSize, page * pageSize)
    )
  }, [page, pageSize])

  const changeCurrentPage = useCallback(
    (newPage: number) => {
      changePage(newPage)
    },
    [changePage]
  )

  const changeCurrentPageSize = useCallback(
    (pageSize: number) => {
      changePageSize(pageSize)
    },
    [changePageSize]
  )

  const changeCurrentSort = useCallback(
    (sort: string) => {
      changeSort(sort)
    },
    [changeSort]
  )

  return {
    data: paginatedUsers,
    originalData: users,
    dataCount: filteredUsers?.length,
    isLoading: isFetching || isFetchingOrgs,
    columns: UserTableColumns,
    pagination: {
      currentPage: page,
      currentPageSize: pageSize,
      currentSort: sort,
      changeCurrentPage,
      changeCurrentPageSize,
      changeCurrentSort
    },
    setSearchAndFilterValues,
    suggestions: UserSuggestions,
    fieldValuesMap
  }
}
