import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Link,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@epilot/base-elements'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import { usePricingTiers } from '~providers'
import { useQueryGetOrganizations } from '~services'

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  container: {
    'max-height': 820,
    'background-color': ' #F5F7FA'
  },
  table: {
    'background-color': ' #F5F7FA'
  },
  accordionDetails: {
    padding: 0
  }
})

export const InUseOrganizations = ({ editingPricingTier }) => {
  const history = useHistory()
  const { pricingTierAssignments, loadingPricingTierAssignments } =
    usePricingTiers()
  const { data: organizations, isFetching: isFetchingOrgs } =
    useQueryGetOrganizations()
  const classes = useStyles()

  const handleClickOrganization =
    (orgId: string) =>
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.stopPropagation()
      event.preventDefault()
      history.push(`/organizations/${orgId}`)
    }

  return (
    <>
      <Box className={classes.root} marginTop={'24px'}>
        <Accordion defaultExpanded={false}>
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            In use organizations
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            {isFetchingOrgs || loadingPricingTierAssignments ? (
              <CircularProgress color="primary" size={20} />
            ) : (
              <TableContainer className={classes.container} component={Paper}>
                <Table className={classes.table} size="small">
                  <TableBody>
                    {pricingTierAssignments
                      ?.filter(
                        (assignment) =>
                          assignment?.pricing_tier_id == editingPricingTier?.id
                      )
                      .map((assignment) => (
                        <TableRow key={assignment?.organization_id}>
                          <TableCell component="th" scope="row">
                            <Box
                              alignItems="center"
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                            >
                              <StyledLink
                                key={assignment?.organization_id}
                                onClick={handleClickOrganization(
                                  assignment?.organization_id
                                )}
                              >
                                {
                                  organizations?.data?.organizations?.find(
                                    (org) =>
                                      org?.id == assignment?.organization_id
                                  )?.name
                                }
                              </StyledLink>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  )
}

const StyledLink = styled(Link)`
  text-decoration: none;
  color: primary;
  margin-right: 4px;
`
