import React, { useEffect, useState } from 'react'

import {
  AutocompleteX,
  Box,
  Button,
  Chip,
  CircularProgress,
  Icon,
  makeStyles,
  TextField
} from '@epilot/base-elements'
import Alert from '@material-ui/lab/Alert'
import { keyBy } from 'lodash'

import { Assignments, Role } from '~apis'
import { formatDateTime, IOrganization, IUser } from '~common'
import { useJSONEditorDrawer, useTags } from '~providers'
import {
  useMutationUpdateAssignedRoleForUser,
  useMutationUpdateUser,
  useQueryGetAllRoles,
  useQueryGetAssignedRolesForUser,
  useQueryGetOrganization
} from '~services'

type Props = {
  isLoading: boolean
  user: IUser
  orgId: string
  userId: string
}

const useStyles = makeStyles({
  container: {
    marginTop: 20,
    width: '50%',
    '& > div:not(:first-child)': {
      marginTop: 30
    }
  }
})

export const UserInfo: React.FC<Props> = ({
  isLoading,
  user,
  orgId,
  userId
}) => {
  const classes = useStyles()
  const { userTags, loadingUserTags } = useTags()
  const [currentUserTags, setCurrentUserTags] = useState([])
  const [userTagInputValue, setUserTagInputValue] = useState('')
  const [enableUpdateBtn, setEnableUpdateBtn] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [allRoleMap, setAllRoleMap] = useState<Record<string, Role>>({})
  const [organization, setOrganization] = useState<IOrganization>(null)
  const [value, setValue] = useState([])
  const [inputValue, setInputValue] = useState('')

  const allRolesQueryResp = useQueryGetAllRoles(
    { orgId },
    {
      enabled: !!orgId,
      onSettled(data, error) {
        if (error) return
        setAllRoleMap(keyBy(data.data.roles, 'id'))
      }
    }
  )

  const [currentUserRoleIds, setCurrentUserRoleIds] = useState<string[]>([])

  const assignedRolesForUserQueryResp = useQueryGetAssignedRolesForUser(
    { orgId, userId },
    {
      enabled: !!orgId && !!userId,
      onSettled(data, error) {
        if (error) return
        setCurrentUserRoleIds(data.data)
      }
    }
  )

  useQueryGetOrganization(
    {
      orgId: user?.organization_id
    },
    {
      enabled: Boolean(user?.organization_id),
      onSettled(data, error) {
        if (error) return
        setOrganization(data?.data as IOrganization)
      }
    }
  )

  useEffect(() => {
    if (user) {
      setCurrentUserTags(user.tags ?? [])
    }
  }, [user])

  useEffect(() => {
    if (currentUserRoleIds && allRoleMap) {
      setValue(
        currentUserRoleIds.map((id) => allRoleMap[id] ?? null).filter(Boolean)
      )
    }
  }, [allRoleMap, currentUserRoleIds])

  const updateUserMutationResp = useMutationUpdateUser(orgId)
  const updateAssignedRoleForUser = useMutationUpdateAssignedRoleForUser(
    userId,
    orgId
  )

  const handleUpdateUser = () => {
    if (!user?.image_uri) {
      // API not allow null value
      delete user?.image_uri
    }
    updateAssignedRoleForUser.mutate(value?.map((v) => v.id) as Assignments)
    updateUserMutationResp.mutate(
      { ...user, tags: currentUserTags },
      {
        onSuccess: () => {
          setEnableUpdateBtn(false)
          setErrorMessage('')
        },
        onError: (err) => {
          setErrorMessage(`Error while updating user. ${err?.['message']}`)
        }
      }
    )
  }

  const { toggleOpen, setJson, setTitle } = useJSONEditorDrawer()

  const handleClickRoleChip = (role: Role) => () => {
    setJson(JSON.stringify(role, null, 2))
    setTitle(role.name)
    toggleOpen()
  }

  if (
    isLoading ||
    allRolesQueryResp.isLoading ||
    assignedRolesForUserQueryResp.isLoading
  ) {
    return <CircularProgress />
  }

  return (
    <Box display="flex" flexDirection="row">
      <Box className={classes.container} display="flex" flexDirection="column">
        <TextField disabled label="Id" value={user?.id} variant="standard" />
        <TextField
          disabled
          label="Name"
          value={user?.display_name}
          variant="standard"
        />
        <TextField
          disabled
          label="Email"
          value={user?.email}
          variant="standard"
        />
        <TextField
          disabled
          label="Status"
          value={user?.status}
          variant="standard"
        />
        <AutocompleteX
          ChipProps={{
            deleteIcon: <Icon name="close" />
          }}
          disableClearable
          freeSolo
          inputValue={userTagInputValue}
          loading={loadingUserTags}
          multiple
          onChange={(_, newValue) => {
            setCurrentUserTags(newValue)
            setEnableUpdateBtn(true)
          }}
          onInputChange={(_, newInputValue) => {
            setUserTagInputValue(newInputValue)
          }}
          options={userTags || []}
          popupIcon={<Icon color="secondary" name="expand_more" />}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Tags"
              placeholder={
                currentUserTags && currentUserTags.length > 0
                  ? ''
                  : 'Select Tags'
              }
              variant="standard"
            />
          )}
          value={currentUserTags || []}
        />
        <AutocompleteX
          getOptionLabel={(option) => option?.name}
          inputValue={inputValue}
          loading={assignedRolesForUserQueryResp.isFetching}
          multiple
          onChange={(_, newValue) => {
            setValue(newValue)
            setEnableUpdateBtn(true)
          }}
          onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
          options={
            allRolesQueryResp?.data?.data?.roles.filter(
              (role) => role?.type != 'org_role'
            ) as Role[]
          }
          popupIcon={<Icon color="secondary" name="expand_more" />}
          renderInput={(params) => (
            <TextField {...params} label="Roles" variant="standard" />
          )}
          renderTags={(roles: Role[]) =>
            roles.map((role: Role, index) => (
              <Chip
                clickable
                key={index}
                label={role.name}
                onClick={handleClickRoleChip(role)}
                onDelete={() => {
                  setValue(value.filter((val) => val?.id != role?.id))
                  setEnableUpdateBtn(true)
                }}
                style={{ margin: 4 }}
                variant="outlined"
              />
            ))
          }
          value={value}
        />
        <TextField
          disabled
          label="Organization ID"
          value={organization?.id}
          variant="standard"
        />
        <TextField
          disabled
          label="Organization name"
          value={organization?.name}
          variant="standard"
        />
        <TextField
          disabled
          label="Customer number"
          value={organization?.customer_number}
          variant="standard"
        />
        <TextField
          disabled
          label="Created Date"
          value={formatDateTime(user?.created_at)}
          variant="standard"
        />
        <Box>
          <Button
            color="secondary"
            disabled={!enableUpdateBtn || updateUserMutationResp.isLoading}
            onClick={handleUpdateUser}
            style={{ textTransform: 'none', marginTop: 20 }}
            variant="contained"
          >
            {updateUserMutationResp.isLoading ? (
              <CircularProgress color={'inherit'} size={20} />
            ) : (
              'Update'
            )}
          </Button>
          {errorMessage && (
            <Alert
              onClose={() => setErrorMessage('')}
              severity="error"
              style={{ margin: '10px 0px' }}
            >
              {errorMessage}
            </Alert>
          )}
        </Box>
      </Box>
    </Box>
  )
}
