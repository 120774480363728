import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from 'react-query'

import { getPricingTierClient, PricingTier, PricingTierAssignment } from '~apis'

const pricingTierClient = getPricingTierClient()

const PRICING_TIER_QUERY_IDS = {
  GET_PRICING_TIERS: 'GET_PRICING_TIERS',
  GET_ORGANIZATION_PRICING_TIER: 'GET_ORGANIZATION_PRICING_TIER',
  GET_PRICING_TIER_BY_ID: 'GET_PRICING_TIER_BY_ID',
  GET_PRICING_TIER_BY_ORGANIZATION_ID: 'GET_PRICING_TIER_BY_ORGANIZATION_ID',
  UPDATE_PRICING_TIER_OF_ORGANIZATION: 'UPDATE_PRICING_TIER_OF_ORGANIZATION',
  GET_ASSIGNED_PRICING_TIER_OF_ORGANIZATION:
    'GET_ASSIGNED_PRICING_TIER_OF_ORGANIZATION',
  GET_ASSIGNED_PRICING_TIER_OF_ORGANIZATIONS:
    'GET_ASSIGNED_PRICING_TIER_OF_ORGANIZATIONS',
  GET_ALL_ASSIGNMENTS: 'GET_ALL_ASSIGNMENTS'
}

export const useQueryGetPricingTiers = (
  opts?: UseQueryOptions<
    Awaited<ReturnType<typeof pricingTierClient.getPricingTiers>>
  >
) => {
  return useQuery(
    [PRICING_TIER_QUERY_IDS.GET_PRICING_TIERS],
    () => pricingTierClient.getPricingTiers({ limit: '1000' }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      retry: false,
      ...opts
    }
  )
}

export const useMutationDeletePricingTier = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (pricingTierId: string) => {
      return pricingTierClient.deletePricingTier({
        pricing_tier_id: pricingTierId
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PRICING_TIER_QUERY_IDS.GET_PRICING_TIERS)
      }
    }
  )
}

export const useQueryGetPricingTierById = (
  pricingTierId: string,
  opts: UseQueryOptions<
    Awaited<ReturnType<typeof pricingTierClient.getPricingTier>>
  > = {}
) => {
  return useQuery(
    [PRICING_TIER_QUERY_IDS.GET_PRICING_TIER_BY_ID, pricingTierId],
    () =>
      pricingTierClient.getPricingTier({
        pricing_tier_id: pricingTierId
      }),
    { refetchOnWindowFocus: false, retry: false, ...opts }
  )
}

export const useQueryGetPricingTierByOrganizationId = (
  pricingTierId: string,
  orgId: string,
  opts: UseQueryOptions<
    Awaited<ReturnType<typeof pricingTierClient.getPricingTier>>
  > = {}
) => {
  return useQuery(
    [
      PRICING_TIER_QUERY_IDS.GET_PRICING_TIER_BY_ORGANIZATION_ID,
      pricingTierId,
      orgId
    ],
    () =>
      pricingTierClient.getPricingTier({
        pricing_tier_id: pricingTierId,
        org_id: orgId
      }),
    { refetchOnWindowFocus: false, retry: false, ...opts }
  )
}

export const useMutationUpdatePricingTier = (orgId?: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    (pricingTier: PricingTier) => {
      return pricingTierClient.updatePricingTier(
        {
          pricing_tier_id: pricingTier?.id,
          org_id: orgId
        },
        pricingTier
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          PRICING_TIER_QUERY_IDS.GET_PRICING_TIER_BY_ID
        )
        queryClient.invalidateQueries(
          PRICING_TIER_QUERY_IDS.GET_PRICING_TIER_BY_ORGANIZATION_ID
        )
      }
    }
  )
}

export const useMutationCreatePricingTier = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (pricingTier: PricingTier) => {
      return pricingTierClient.createPricingTier(null, pricingTier)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PRICING_TIER_QUERY_IDS.GET_PRICING_TIERS)
      }
    }
  )
}

export const useMutationAssignPricingTier = () => {
  return useMutation((assignment: PricingTierAssignment) => {
    return pricingTierClient.assignPricingTier(
      { org_id: assignment.organization_id },
      assignment
    )
  })
}

export const useQueryGetAssignedPricingTierForOrganization = (
  orgId: string,
  opts: UseQueryOptions<
    Awaited<
      ReturnType<typeof pricingTierClient.getAssignedPricingTierForOrganization>
    >
  > = {}
) => {
  return useQuery(
    [PRICING_TIER_QUERY_IDS.GET_ASSIGNED_PRICING_TIER_OF_ORGANIZATION, orgId],
    () =>
      pricingTierClient.getAssignedPricingTierForOrganization({
        org_id: orgId
      }),
    { refetchOnWindowFocus: false, retry: false, ...opts }
  )
}

export const useQueryGetAssignedPricingTiersForOrganizations = (
  organizationIds: string[],
  options?: UseQueryOptions<
    Awaited<
      ReturnType<typeof pricingTierClient.getAssignedPricingTierForOrganization>
    >[]
  >
) => {
  return useQuery(
    [
      PRICING_TIER_QUERY_IDS.GET_ASSIGNED_PRICING_TIER_OF_ORGANIZATIONS,
      organizationIds
    ],
    () =>
      Promise.all(
        organizationIds.map((orgId) =>
          pricingTierClient.getAssignedPricingTierForOrganization({
            org_id: orgId
          })
        )
      ),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: organizationIds.length > 0,
      ...options
    }
  )
}

export const useQueryGetPricingTierAssignments = (
  opts?: UseQueryOptions<
    Awaited<ReturnType<typeof pricingTierClient.getAssignments>>
  >
) => {
  return useQuery(
    [PRICING_TIER_QUERY_IDS.GET_ALL_ASSIGNMENTS],
    () => pricingTierClient.getAssignments(),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      retry: false,
      ...opts
    }
  )
}
