import { useState } from 'react'

import {
  Box,
  createStyles,
  makeStyles,
  PrimaryTabs,
  Tab,
  TabContext,
  TabPanel
} from '@epilot/base-elements'
import { useParams } from 'react-router'

import { IUser } from '~common'
import { useQueryParams } from '~hooks'
import { useQueryGetUserById } from '~services'

import { UserInfo } from '../organisms'

export enum TABS {
  USER_DETAILS = 'USER_DETAILS',
  PERMISSIONS = 'PERMISSIONS'
}

const useStyles = makeStyles(() =>
  createStyles({
    tabPanel: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1
    },
    input: {
      height: 44
    }
  })
)

export const UpdateUserPage = () => {
  const classes = useStyles()
  const [currentTab, setCurrentTab] = useState<TABS>(TABS.USER_DETAILS)
  const handleTabChange = (_, newValue: TABS) => {
    setCurrentTab(newValue)
  }

  const { id } = useParams<{ id: string }>()
  const { orgId } = useQueryParams<{ orgId: string }>()

  const { isFetching, data } = useQueryGetUserById(id, orgId, {
    enabled: !!id && !!orgId
  })

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      style={{ height: '100%' }}
    >
      <TabContext value={currentTab}>
        <PrimaryTabs
          aria-label="simple tabs example"
          indicatorColor="primary"
          onChange={handleTabChange as never}
        >
          <Tab label={'Users Details'} value={TABS.USER_DETAILS} />
        </PrimaryTabs>
        <TabPanel
          className={
            currentTab === TABS.USER_DETAILS ? classes.tabPanel : undefined
          }
          value={TABS.USER_DETAILS}
        >
          <Box display="flex" flexDirection="column" py={3}>
            <UserInfo
              isLoading={isFetching}
              orgId={orgId}
              user={data?.data as IUser}
              userId={id}
            />
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  )
}
