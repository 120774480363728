import { useMemo } from 'react'

import { Components, getClient } from '@epilot/entity-client'

import { URL_CONFIG } from '~configs'

import { configureClient } from './utils.client'

export const getEntityClient = () => {
  const client = getClient()

  return configureClient(client, {
    baseURL: URL_CONFIG.ENTITY
  })
}

export const useEntityClient = () => useMemo(() => getEntityClient(), [])

export type EntityItem = Components.Schemas.EntityItem
