import React, { useEffect, useState } from 'react'

import { AutocompleteX, Chip, Icon, TextField } from '@epilot/base-elements'
import cloneDeep from 'lodash/cloneDeep'
import styled from 'styled-components'

import { SearchSuggestion } from '~common'

interface Props {
  onChange: React.Dispatch<React.SetStateAction<SearchSuggestion[]>>
  suggestions: SearchSuggestion[]
  fieldValuesMap?: Record<string, string[]>
}

export const TemplateSearchBar = (props: Props) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [values, setValues] = useState<SearchSuggestion[]>([])

  const [suggestions, setSuggestions] = useState<SearchSuggestion[]>([
    ...props.suggestions
  ])
  const { onChange } = props

  useEffect(() => {
    // render input value as first autocomplete suggestion
    if (inputValue) {
      const clonedSuggestionsProp = cloneDeep(props.suggestions)

      if (inputValue.match(':')) {
        const field = inputValue.split(':')[0]?.trim()

        if (Array.isArray(props.fieldValuesMap[field])) {
          clonedSuggestionsProp.push(
            ...props.fieldValuesMap[field].map((value) => ({
              type: 'filter' as const,
              label: `Filter: ${field}:${value}`,
              value: `${field}:${value}`
            }))
          )
        }

        clonedSuggestionsProp.unshift({
          type: 'filter',
          label: `Filter: ${inputValue}`,
          value: inputValue
        })
        setSuggestions(clonedSuggestionsProp)
      } else {
        clonedSuggestionsProp.unshift({
          type: 'search',
          label: `Search: ${inputValue}`,
          value: inputValue
        })
        setSuggestions(clonedSuggestionsProp)
      }
    } else {
      setSuggestions(props.suggestions)
    }
  }, [inputValue, props.fieldValuesMap, props.suggestions])

  useEffect(() => {
    onChange(values)
  }, [onChange, values])

  const handleAutocompleteValuesChange = (newValues: SearchSuggestion[]) => {
    const valueToAdd = newValues[newValues.length - 1]

    // no duplicate search or filter values
    const uniqueValues = {}

    newValues.forEach((elem) => {
      if (
        elem.type === 'filter' &&
        elem.value?.split(':')?.[0] &&
        elem.value?.split(':')?.[1]
      ) {
        uniqueValues[`filter-${elem.value.split(':')[0]}`] =
          elem.value.split(':')[1]
      }

      if (elem.type === 'search') {
        uniqueValues[`search-${elem.value}`] = elem.value
      }
    })

    if (valueToAdd?.append) {
      setInputValue(valueToAdd.append)
    } else {
      setValues(
        Object.keys(uniqueValues).map((key) => {
          const results = key.split('-')

          const type = results[0]
          let tag = results[1]

          if (results.length > 2) {
            results.shift()
            tag = results.join('-')
          }

          if (type === 'filter') {
            return {
              type: 'filter',
              value: `${tag}:${uniqueValues[key]}`,
              label: `Filter: ${tag}:${uniqueValues[key]}`
            }
          }

          return {
            type: 'search',
            value: uniqueValues[key],
            label: `Search: ${uniqueValues[key]}`
          }
        })
      )
    }
  }

  const onDeleteSuggestion = (suggestion: SearchSuggestion) => () => {
    const newValues = values.filter(
      (value: SearchSuggestion) => value.label !== suggestion.label
    )

    handleAutocompleteValuesChange(newValues)
  }

  return (
    <Container>
      <SearchBar
        ChipProps={{
          deleteIcon: <Icon name="close" />
        }}
        autoHighlight
        getOptionLabel={(option: SearchSuggestion) => option.label}
        includeInputInList
        inputValue={inputValue}
        multiple
        noOptionsText="No options matching..."
        onChange={(_, values: unknown) =>
          handleAutocompleteValuesChange(values as SearchSuggestion[])
        }
        onInputChange={(_, value) => setInputValue(value)}
        options={suggestions}
        popupIcon={<Icon color="secondary" name="search" />}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={'Search or filter table'}
            variant="outlined"
          />
        )}
        renderTags={() => null}
        value={values}
      />

      <TagsContainer>
        {values?.map((suggestion: SearchSuggestion) => (
          <Tag
            deleteIcon={<Icon name="close" />}
            key={suggestion.label}
            label={suggestion.value}
            onDelete={onDeleteSuggestion(suggestion)}
          />
        ))}
      </TagsContainer>
    </Container>
  )
}

const Container = styled.div`
  flex: 1;
`

const TagsContainer = styled.div`
  margin-top: 12px;
  min-height: 28px;

  .admin-portal-MuiChip-root {
    margin-right: 4px;
    margin-bottom: 4px;
  }
`

const Tag = styled(Chip)`
  && {
    background-color: rgb(222, 234, 247);
  }
`

const SearchBar = styled(AutocompleteX)`
  width: 100%;
  max-width: 710px;

  .admin-portal-MuiInputBase-root {
    background: white;
  }

  .admin-portal-MuiAutocomplete-popupIndicatorOpen {
    transform: rotate(0deg);
  }
`
