import { useCallback, useEffect, useState } from 'react'

import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import { useUpdateEffect } from 'react-use'

import {
  formatDateTime,
  IOrganization,
  ITableColumn,
  parseFieldValuesSuggestionMap,
  parseSearchAndFilterSuggestion,
  SearchSuggestion
} from '~common'
import { useDataPoints, usePricingTiers } from '~providers'
import { useQueryGetOrganizations } from '~services'

import { useTableConfig } from './useTableConfig'

export const OrganizationTableColumns: ITableColumn[] = [
  {
    field: 'id',
    displayName: 'Id',
    align: 'left',
    width: 100,
    enableFiltering: true
  },
  {
    field: 'customer_number',
    displayName: 'Customer number',
    align: 'left',
    width: 200,
    enableFiltering: true
  },
  {
    field: 'name',
    displayName: 'Name',
    align: 'left',
    width: 200,
    enableFiltering: true
  },
  {
    field: 'symbol',
    displayName: 'Symbol',
    align: 'left',
    width: 100,
    enableFiltering: true
  },
  {
    field: 'type',
    displayName: 'Type',
    align: 'left',
    width: 100,
    enableFiltering: true
  },
  {
    field: 'source',
    displayName: 'Source',
    align: 'left',
    width: 100,
    type: 'source',
    enableFiltering: true
  },
  {
    field: 'status',
    displayName: 'Status',
    align: 'left',
    width: 200,
    enableFiltering: true
  },
  {
    field: 'new_pricing_tier',
    displayName: 'Pricing tier',
    align: 'left',
    width: 200,
    enableFiltering: true
  },
  {
    field: 'actual_users',
    displayName: 'Actual user',
    align: 'left',
    width: 200,
    type: 'dataPoint'
  },
  {
    field: 'max_users_last_month',
    displayName: 'Max user last month',
    align: 'left',
    width: 200,
    type: 'dataPoint'
  },
  {
    field: 'max_billable_users_last_month',
    displayName: 'Max billable user last month',
    align: 'left',
    width: 250,
    type: 'dataPoint'
  },
  {
    field: 'additional_paid_users',
    displayName: 'Additional paid user',
    align: 'left',
    width: 200,
    type: 'dataPoint'
  },
  {
    field: 'actual_customer',
    displayName: 'Actual customer',
    align: 'left',
    width: 200,
    type: 'dataPoint'
  },
  {
    field: 'max_customer',
    displayName: 'Max customer',
    align: 'left',
    width: 200,
    type: 'dataPoint'
  },
  {
    field: 'active_partners',
    displayName: 'Active partnerships',
    align: 'left',
    width: 200,
    type: 'partner',
    disableSorting: true
  },
  {
    field: 'actual_partner_users',
    displayName: 'Actual partner users',
    align: 'left',
    width: 200,
    type: 'partner',
    disableSorting: true
  },
  {
    field: 'max_partner_users',
    displayName: 'Max partner users last month',
    align: 'left',
    width: 250,
    type: 'partner',
    disableSorting: true
  },
  {
    field: 'email',
    displayName: 'Email',
    align: 'left',
    width: 200,
    enableFiltering: true
  },
  {
    field: 'tags',
    displayName: 'Tags',
    align: 'left',
    width: 200,
    enableFiltering: true
  },
  {
    field: 'created_date',
    displayName: 'Creation date',
    align: 'left',
    width: 200
  }
]

const OrganizationSuggestions: SearchSuggestion[] =
  OrganizationTableColumns.filter((column) => column.enableFiltering).map(
    (column) => {
      return {
        type: 'filter',
        label: `Filter: ${column.displayName}`,
        append: `${column.field}:`
      }
    }
  )

export const useOrganizationTable = () => {
  const [organizations, setOrganizations] = useState<IOrganization[]>([])
  const [filteredOrganizations, setFilteredOrganizations] = useState<
    IOrganization[]
  >([])
  const [paginatedOrganizations, setPaginatedOrganizations] = useState<
    IOrganization[]
  >([])

  const [fieldValuesMap, setFieldValuesMap] = useState<
    Record<string, string[]>
  >({})

  const [searchAndFilterValues, setSearchAndFilterValues] = useState<
    SearchSuggestion[]
  >([])

  const { isFetching, data, isFetched } = useQueryGetOrganizations()
  const { mergedDataPointMap, loading: loadingDataPoint } = useDataPoints()

  const {
    loadingPricingTiers,
    pricingTiers,
    loadingPricingTierAssignments,
    pricingTierAssignments
  } = usePricingTiers()

  useEffect(() => {
    if (isFetched && data?.data) {
      const organizations = (
        filter(
          (data.data as unknown as Record<string, unknown>)
            ?.organizations as IOrganization[],
          (organization) => organization && organization.id
        ) ?? []
      ).map((elem: IOrganization) => ({
        ...elem,
        created_date: formatDateTime(elem.created_date, 'en'),
        source: elem.is_unlicensed_org ? 'Signup' : 'Admin'
      }))

      setOrganizations(organizations)
    }
  }, [data, isFetched])

  const {
    data: { pageSize, page, sort },
    actions: { changePage, changePageSize, changeSort }
  } = useTableConfig('admin-portal-organizations')

  useUpdateEffect(() => {
    const enableFilteringFields = OrganizationTableColumns.filter(
      (column) => column.enableFiltering
    ).map((column) => column.field)

    setFieldValuesMap(
      parseFieldValuesSuggestionMap(organizations, enableFilteringFields)
    )
  }, [organizations])

  // sort organization table column
  useUpdateEffect(() => {
    if (sort.split(':')?.length > 0) {
      type SortOrder = boolean | 'asc' | 'desc'
      const [field, order] = sort.split(':')

      setFilteredOrganizations((prevState) => {
        const sortedOrgs = orderBy(
          prevState.map((elem) => ({
            ...elem,
            id: Number(elem.id)
          })),
          [field],
          [order as SortOrder]
        )

        return sortedOrgs.map((org) => ({ ...org, id: String(org.id) }))
      })
    }
  }, [sort])

  useUpdateEffect(() => {
    const { filterKeyValueMap, searchValue } = parseSearchAndFilterSuggestion(
      searchAndFilterValues
    )

    let filteredOrgs = cloneDeep(organizations)

    if (!isEmpty(filterKeyValueMap)) {
      filteredOrgs = organizations.filter((org) =>
        Object.entries(filterKeyValueMap).every(([key, value]) => {
          if (isArray(org[key])) {
            return org[key].includes(value)
          }

          return org[key] === value
        })
      )
    }

    if (searchValue) {
      filteredOrgs = filteredOrgs.filter(
        (org) =>
          org.id == searchValue ||
          org.symbol?.toLowerCase().includes(searchValue) ||
          org.name?.toLowerCase().includes(searchValue?.toLowerCase()) ||
          org.email?.toLowerCase().includes(searchValue?.toLowerCase())
      )
    }

    setFilteredOrganizations(filteredOrgs)
  }, [searchAndFilterValues])

  useUpdateEffect(() => {
    setFilteredOrganizations(organizations)
  }, [organizations])

  // new search results, reset current page to 1
  useUpdateEffect(() => {
    setPaginatedOrganizations(filteredOrganizations.slice(0, pageSize))
    changeCurrentPage(1)
  }, [filteredOrganizations])

  // normal pagination action
  useUpdateEffect(() => {
    setPaginatedOrganizations(
      filteredOrganizations.slice((page - 1) * pageSize, page * pageSize)
    )
  }, [page, pageSize])

  useEffect(() => {
    if (!loadingDataPoint) {
      setOrganizations((prevState) =>
        prevState.map((org) => {
          const dataPoint = mergedDataPointMap[org?.id]

          let additional_paid_users = 0
          let max_billable_users_last_month = 0
          const actual_users = dataPoint?.actual_users ?? 0
          const max_users_last_month = dataPoint?.max_users_last_month ?? 0
          const actual_customer = dataPoint?.actual_customer ?? 0
          const max_customer = dataPoint?.max_customer ?? 0

          if (org.type !== 'Partner' && dataPoint) {
            max_billable_users_last_month =
              dataPoint.non_billable_users_last_month > 0
                ? dataPoint.max_users_last_month -
                  dataPoint.non_billable_users_last_month
                : dataPoint.max_users_last_month
            if (
              +org?.free_user_limit > 0 &&
              max_billable_users_last_month - +org?.free_user_limit > 0
            ) {
              additional_paid_users =
                max_billable_users_last_month - +org.free_user_limit
            }
          }

          return {
            ...org,
            additional_paid_users,
            max_billable_users_last_month,
            actual_users,
            max_users_last_month,
            actual_customer,
            max_customer
          }
        })
      )
    }
    if (!loadingPricingTiers && !loadingPricingTierAssignments) {
      setOrganizations((prevState) =>
        prevState.map((org) => {
          const pricingTierId = pricingTierAssignments?.find(
            (p) => p?.organization_id == org?.id
          )?.pricing_tier_id

          return {
            ...org,
            new_pricing_tier: pricingTiers?.find((p) => p?.id == pricingTierId)
              ?.name
          }
        })
      )
    }
  }, [
    mergedDataPointMap,
    loadingDataPoint,
    loadingPricingTiers,
    loadingPricingTierAssignments,
    pricingTierAssignments,
    pricingTiers
  ])

  const changeCurrentPage = useCallback(
    (newPage: number) => {
      changePage(newPage)
    },
    [changePage]
  )

  const changeCurrentPageSize = useCallback(
    (pageSize: number) => {
      changePageSize(pageSize)
    },
    [changePageSize]
  )

  const changeCurrentSort = useCallback(
    (sort: string) => {
      changeSort(sort)
    },
    [changeSort]
  )

  return {
    data: paginatedOrganizations,
    originalData: organizations,
    dataCount: filteredOrganizations?.length,
    isLoading: isFetching,
    columns: OrganizationTableColumns,
    suggestions: OrganizationSuggestions,
    fieldValuesMap,
    setSearchAndFilterValues,
    pagination: {
      currentPage: page,
      currentPageSize: pageSize,
      currentSort: sort,
      changeCurrentPage,
      changeCurrentPageSize,
      changeCurrentSort
    }
  }
}
