import { useEffect, useState } from 'react'

import {
  AutocompleteX,
  Box,
  Button,
  CircularProgress,
  Icon,
  makeStyles,
  TextField
} from '@epilot/base-elements'
import Alert from '@material-ui/lab/Alert'
import _, { isEmpty } from 'lodash'

import { getUserClient, Organization, PricingTier } from '~apis'
import { useTags } from '~providers'
import {
  useMutationCreateOrganization,
  useQueryGetPricingTiers
} from '~services'

import { ORGANIZATION_LIST_URL } from '../../../routes/RouteUtils'
import { OwnerInfo } from './UpdateOrganizationPage'

const useStyles = makeStyles({
  container: {
    marginTop: 20,
    width: '50%',
    '& > div:not(:first-child)': {
      marginTop: 20
    }
  }
})

export const CreateOrganizationPage = () => {
  const classes = useStyles()

  const [organization, setOrganization] = useState<Organization>({})
  const [ownerInfo, setOwnerInfo] = useState<OwnerInfo>({})

  const [inputValue, setInputValue] = useState('')
  const { organizationTags } = useTags()
  const [values, setValues] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [pricingTierInputValue, setPricingTierInputValue] = useState('')
  const [pricingTierValue, setPricingTierValue] = useState(null)
  const { data: pricingTierData } = useQueryGetPricingTiers()
  const [validateErrorMessage, setValidateErrorMessage] = useState({})

  const createOrganization = useMutationCreateOrganization()

  useEffect(() => {
    if (
      isEmpty(pricingTierValue) &&
      pricingTierData?.data?.pricing_tiers.length > 0
    ) {
      setPricingTierValue(pricingTierData?.data?.pricing_tiers[0])
      setOrganization((prevState) => ({
        ...prevState,
        pricing_tier_id: pricingTierValue?.id
      }))
    }
  }, [pricingTierData?.data?.pricing_tiers, pricingTierValue])

  const handleOrgChange =
    (path: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value

      setOrganization((prevState) => {
        const newConfig = _.cloneDeep(prevState)

        _.set(newConfig, path, value)

        return newConfig
      })
    }

  const handleOwnerChange =
    (path: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value

      setOwnerInfo((prevState) => {
        const newConfig = _.cloneDeep(prevState)

        _.set(newConfig, path, value)

        return newConfig
      })
    }

  const handleCreateOrganization = () => {
    if (
      !organization?.name?.trim() ||
      !organization?.pricing_tier_id ||
      !ownerInfo?.email_address?.trim()
    ) {
      setErrorMessage('Please fill all the required fields')

      return
    }
    createOrganization.mutate(
      {
        organization_detail: {
          name: organization.name,
          email_address: organization?.email,
          type: 'Vendor',
          pricing_tier_id: organization?.pricing_tier_id
        },
        owner_user: {
          full_name: ownerInfo?.full_name,
          email_address: ownerInfo.email_address
        }
      },
      {
        onSuccess: () => {
          setErrorMessage('')
          window.location.href = ORGANIZATION_LIST_URL
        },
        onError: (err) => {
          setErrorMessage(
            `Error while creating organization. ${err?.['response']?.data?.error}`
          )
        }
      }
    )
  }

  const getPricingTierName = (pricingTier?: PricingTier) =>
    pricingTier?.name ||
    pricingTierData?.data?.pricing_tiers.find((p) => p.id == pricingTier.id)
      ?.name ||
    pricingTier?.id

  const validate = (field: string) => async (event) => {
    if (_.isEmpty(event.target.value?.trim())) {
      setValidateErrorMessage({
        ...validateErrorMessage,
        [field]: `${field} is required`
      })
    } else {
      if (field == 'Email') {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const isValidEmail = re.test(
          String(event.target.value?.trim()).toLowerCase()
        )

        if (!isValidEmail) {
          setValidateErrorMessage({
            ...validateErrorMessage,
            [field]: `${field} is invalid`
          })

          return
        } else {
          const userClient = getUserClient()

          let isEmailExist = false

          try {
            await userClient.getUserLoginParameters(event.target.value?.trim())
            isEmailExist = true
          } catch (err) {
            // User already exists but not activated
            if (err?.response?.data?.error === 'User not active') {
              isEmailExist = true

              return
            }

            if (err?.response?.data?.status === 404) {
              isEmailExist = false
            }
          }

          if (isEmailExist) {
            console.log('email exists')
            setValidateErrorMessage({
              ...validateErrorMessage,
              [field]: `${field} already exists`
            })

            return
          }
        }
      }
      const newError = { ...validateErrorMessage }

      delete newError[field]
      setValidateErrorMessage(newError)
    }
  }
  const isShowError = (field: string, value: string) => {
    return !_.isEmpty(validateErrorMessage[field]) && !_.isEmpty(value)
  }

  return (
    <>
      <Box display="flex" flexDirection="row">
        <Box
          className={classes.container}
          display="flex"
          flexDirection="column"
        >
          <p>Organization Info</p>
          <TextField
            error={isShowError('Name', organization?.name)}
            helperText={validateErrorMessage['Name']}
            label="Name"
            onBlur={validate('Name')}
            onChange={handleOrgChange('name')}
            required
            value={organization?.name || ''}
            variant="standard"
          />
          <TextField
            label="Email"
            onChange={handleOrgChange('email')}
            value={organization?.email || ''}
            variant="standard"
          />
          <TextField disabled label="Type" value="Vendor" variant="standard" />
          <AutocompleteX
            freeSolo={false}
            getOptionLabel={getPricingTierName}
            getOptionSelected={(option, value) => option.id === value.id}
            inputValue={pricingTierInputValue}
            onChange={(_, newValue) => {
              setPricingTierValue(newValue)
              setOrganization((prevState) => ({
                ...prevState,
                pricing_tier_id: newValue?.['id']
              }))
            }}
            onInputChange={(_, newInputValue) => {
              setPricingTierInputValue(newInputValue)
            }}
            options={pricingTierData?.data?.pricing_tiers || []}
            popupIcon={<Icon color="secondary" name="expand_more" />}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Pricing tier*"
                placeholder={
                  values && values.length > 0 ? '' : 'Select pricing tier'
                }
                variant="standard"
              />
            )}
            value={pricingTierValue}
          />
          <AutocompleteX
            ChipProps={{
              deleteIcon: <Icon name="close" />
            }}
            inputValue={inputValue}
            multiple
            noOptionsText="No options matching..."
            onChange={(_, newValue) => setValues(newValue)}
            onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
            options={organizationTags || []}
            popupIcon={<Icon color="secondary" name="expand_more" />}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tags"
                placeholder={values && values.length > 0 ? '' : 'Select tags'}
                variant="standard"
              />
            )}
            value={values || []}
          />
        </Box>
        <Box
          className={classes.container}
          display="flex"
          flexDirection="column"
          style={{ marginLeft: 20 }}
        >
          <p>Owner Info</p>
          <TextField
            error={isShowError('Email', ownerInfo?.email_address)}
            helperText={validateErrorMessage['Email']}
            label="Owner email"
            onBlur={validate('Email')}
            onChange={handleOwnerChange('email_address')}
            placeholder="This email will receive invitation email"
            required
            value={ownerInfo.email_address || ''}
            variant="standard"
          />
          <TextField
            label="Owner full name"
            onChange={handleOwnerChange('full_name')}
            value={ownerInfo.full_name || ''}
            variant="standard"
          />
        </Box>
      </Box>
      <Box>
        <Button
          color="secondary"
          disabled={!_.isEmpty(validateErrorMessage)}
          onClick={handleCreateOrganization}
          style={{ textTransform: 'none', marginTop: 20 }}
          variant="contained"
        >
          {createOrganization.isLoading ? (
            <CircularProgress color={'inherit'} size={20} />
          ) : (
            'Create'
          )}
        </Button>
        {errorMessage && (
          <Alert
            onClose={() => setErrorMessage('')}
            severity="error"
            style={{ margin: '10px 0px' }}
          >
            {errorMessage}
          </Alert>
        )}
      </Box>
    </>
  )
}
