import React, { createContext, useContext, useMemo, useState } from 'react'

import {
  difference,
  differenceBy,
  flatten,
  keyBy,
  reduce,
  uniq,
  uniqBy
} from 'lodash'

import { OrgAssignments, OrgRoles, Role } from '~apis'
import { IUser } from '~common'
import {
  useQueryGetAllAssignmentsAllOrgs,
  useQueryGetAllRolesAllOrgs,
  useQueryGetAllRolesByOrgIds,
  useQueryGetAssignedRolesForUsers
} from '~services'

export interface IRoleAndPermissionContext {
  rolesMap: Record<string, Role>
  userIdAssignedRoleIdsMap: Record<string, string[]>
  loadingGetAllRolesOrgIds: boolean
  loadingGetAssignedRoleUsers: boolean
  setGetAllRolesOrgIds: React.Dispatch<React.SetStateAction<string[]>>
  setGetAssignedRoleUsers: React.Dispatch<React.SetStateAction<IUser[]>>
  allAssignmentsAllOrgs: OrgAssignments[]
  allRolesAllOrgs: OrgRoles[]
  isReadyExport: boolean
}

const RoleAndPermissionContext = createContext<IRoleAndPermissionContext>({
  rolesMap: {},
  loadingGetAllRolesOrgIds: false,
  loadingGetAssignedRoleUsers: false,
  setGetAllRolesOrgIds: () => [],
  setGetAssignedRoleUsers: () => [],
  userIdAssignedRoleIdsMap: {},
  allAssignmentsAllOrgs: [],
  allRolesAllOrgs: [],
  isReadyExport: false
})

export const RoleAndPermissionContextProvider: React.FC = ({ children }) => {
  const [rolesMap, setRolesMap] = useState<Record<string, Role>>({})
  const [userIdAssignedRoleIdsMap, setUserIdAssignedRoleIdsMap] = useState<
    Record<string, string[]>
  >({})

  const [allAssignmentsAllOrgs, setAllAssignmentsAllOrgs] = useState<
    OrgAssignments[]
  >([])

  const [allRolesAllOrgs, setAllRolesAllOrgs] = useState<OrgRoles[]>([])

  const [getAllRolesOrgIds, setGetAllRolesOrgIds] = useState<string[]>([])
  const [cachedGetAllRoleOrgIds, setCachedGetAllRoleOrgIds] = useState<
    string[]
  >([])

  const [getAssignedRoleUsers, setGetAssignedRoleUsers] = useState<IUser[]>([])
  const [cachedGetAssignedRoleUsers, setCachedGetAssignedRoleUsers] = useState<
    IUser[]
  >([])

  const getAllRolesByOrgIdsQuery = useQueryGetAllRolesByOrgIds(
    uniq(difference(getAllRolesOrgIds, cachedGetAllRoleOrgIds)),
    {
      onSuccess(responses) {
        setRolesMap((prevState) => ({
          ...prevState,
          ...keyBy(flatten(responses.map((resp) => resp.data.roles)), 'id')
        }))

        setCachedGetAllRoleOrgIds((prevState) =>
          uniq([
            ...prevState,
            ...difference(getAllRolesOrgIds, cachedGetAllRoleOrgIds)
          ])
        )
      }
    }
  )

  const filteredGetAssignedRoleUsers = useMemo(
    () =>
      uniqBy(
        differenceBy(getAssignedRoleUsers, cachedGetAssignedRoleUsers, 'id'),
        'id'
      ),
    [getAssignedRoleUsers, cachedGetAssignedRoleUsers]
  )

  const getAssignedRolesForUsersQuery = useQueryGetAssignedRolesForUsers(
    filteredGetAssignedRoleUsers,
    {
      onSuccess(responses) {
        setUserIdAssignedRoleIdsMap((prevState) => ({
          ...prevState,
          ...reduce(
            filteredGetAssignedRoleUsers,
            (result, user, index) => {
              result = {
                ...result,
                [user.id]: responses[index].data
              }

              return result
            },
            {}
          )
        }))

        setCachedGetAssignedRoleUsers((prevState) =>
          uniqBy(
            [
              ...prevState,
              ...differenceBy(
                getAssignedRoleUsers,
                cachedGetAssignedRoleUsers,
                'id'
              )
            ],
            'id'
          )
        )
      }
    }
  )

  const getAllAssignmentsAllOrgsQuery = useQueryGetAllAssignmentsAllOrgs({
    onSuccess(responses) {
      setAllAssignmentsAllOrgs(responses.data.assignments)
    }
  })

  const getAllRolesAllOrgsQuery = useQueryGetAllRolesAllOrgs({
    onSuccess(responses) {
      setAllRolesAllOrgs(responses.data.roles)
    }
  })

  return (
    <RoleAndPermissionContext.Provider
      value={{
        loadingGetAllRolesOrgIds: getAllRolesByOrgIdsQuery.isLoading,
        loadingGetAssignedRoleUsers: getAssignedRolesForUsersQuery.isLoading,
        rolesMap,
        userIdAssignedRoleIdsMap,
        setGetAssignedRoleUsers,
        setGetAllRolesOrgIds,
        allAssignmentsAllOrgs,
        allRolesAllOrgs,
        isReadyExport:
          !getAllAssignmentsAllOrgsQuery.isLoading &&
          !getAllRolesAllOrgsQuery.isLoading
      }}
    >
      {children}
    </RoleAndPermissionContext.Provider>
  )
}

export const useRoleAndPermission = () => useContext(RoleAndPermissionContext)
