import _ from 'lodash/fp'

const LS_TABLES_CONFIG = 'tablesConfig'

export const DEFAULT_PAGE_SIZE = 25
export const DEFAULT_PAGE_NUMBER = 1
export const DEFAULT_COLUMN_SETTINGS = []
export const DEFAULT_SORT = '_created_at:desc'
export const DEFAULT_COLUMN_WIDTH = 200

const DEFAULT_TABLE_CONFIG = {
  pageSize: DEFAULT_PAGE_SIZE,
  page: DEFAULT_PAGE_NUMBER,
  columnSettings: DEFAULT_COLUMN_SETTINGS,
  sort: DEFAULT_SORT
}

export interface ColumnSettings {
  show: boolean
  field: string
  width: number
}

export interface TableConfigData {
  pageSize: number
  page: number
  columnSettings: ColumnSettings[]
  sort: string
}

export interface TablesConfig {
  [tableKey: string]: TableConfigData
}

type LSTableConfig = Partial<TableConfigData>

export const updateConfig = (
  tableKey: string,
  tableConfigChanges: LSTableConfig
) => {
  const tablesConfig: TablesConfig = JSON.parse(
    localStorage.getItem(LS_TABLES_CONFIG) || '{}'
  )

  const updatedTablesConfig = {
    ...tablesConfig,
    [tableKey]: _.merge(tablesConfig?.[tableKey], tableConfigChanges)
  }

  localStorage.setItem(LS_TABLES_CONFIG, JSON.stringify(updatedTablesConfig))
}

export const getConfig = (tableKey: string): TableConfigData => {
  if (!tableKey) {
    return DEFAULT_TABLE_CONFIG
  }

  const tablesConfig: TablesConfig = JSON.parse(
    localStorage.getItem(LS_TABLES_CONFIG) || '{}'
  )

  const schemaTableConfig: TableConfigData = tablesConfig?.[tableKey]

  if (!schemaTableConfig) {
    return DEFAULT_TABLE_CONFIG
  }

  return { ...DEFAULT_TABLE_CONFIG, ...schemaTableConfig }
}
