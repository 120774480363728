import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from 'react-query'

import { Assignments, getPermissionsClient } from '~apis'
import { IUser } from '~common'

export enum PERMISSIONS_QUERY_IDS {
  LIST_ALL_ROLES = 'LIST_ALL_ROLES',
  LIST_ALL_ROLES_BY_ORG_IDS = 'LIST_ALL_ROLES_BY_ORG_IDS',
  GET_ASSIGNED_ROLES_FOR_USER = 'GET_ASSIGNED_ROLES_FOR_USER',
  GET_ASSIGNED_ROLES_FOR_USERS = 'GET_ASSIGNED_ROLES_FOR_USERS',
  LIST_ALL_ASSIGNMENTS = 'LIST_ALL_ASSIGNMENTS',
  GET_ALL_ASSIGNMENTS_ALL_ORGS = 'GET_ALL_ASSIGNMENTS_ALL_ORGS',
  GET_ALL_ROLES_ALL_ORGS = 'GET_ALL_ROLES_ALL_ORGS',
  UPDATE_ASSIGNED_ROLES_FOR_USER = 'UPDATE_ASSIGNED_ROLES_FOR_USER'
}

const client = getPermissionsClient()

export const useQueryGetAllAssignmentsAllOrgs = (
  opts: UseQueryOptions<
    Awaited<ReturnType<typeof client.getAllAssignments>>
  > = {}
) => {
  return useQuery(
    [PERMISSIONS_QUERY_IDS.GET_ALL_ASSIGNMENTS_ALL_ORGS],
    () => client.getAllAssignments(),
    { refetchOnWindowFocus: false, ...opts }
  )
}

export const useQueryGetAllRolesAllOrgs = (
  opts: UseQueryOptions<Awaited<ReturnType<typeof client.getAllRoles>>> = {}
) => {
  return useQuery(
    [PERMISSIONS_QUERY_IDS.GET_ALL_ROLES_ALL_ORGS],
    () => client.getAllRoles(),
    { refetchOnWindowFocus: false, ...opts }
  )
}

export const useQueryGetAllAssignments = (
  orgId: string,
  opts: UseQueryOptions<
    Awaited<ReturnType<typeof client.listAllAssignments>>
  > = {}
) => {
  return useQuery(
    [PERMISSIONS_QUERY_IDS.LIST_ALL_ASSIGNMENTS, orgId],
    () =>
      client.listAllAssignments(
        {},
        {},
        {
          headers: {
            'x-ivy-org-id': orgId
          }
        }
      ),
    { refetchOnWindowFocus: true, ...opts }
  )
}

export const useQueryGetAllRoles = (
  { orgId }: { orgId: string },
  options?: UseQueryOptions<Awaited<ReturnType<typeof client.listAllRoles>>>
) => {
  return useQuery(
    [PERMISSIONS_QUERY_IDS.LIST_ALL_ROLES, orgId],
    () =>
      client.listAllRoles(
        {},
        {},
        {
          headers: {
            'x-ivy-org-id': orgId
          }
        }
      ),
    { refetchOnWindowFocus: false, retry: false, ...options }
  )
}

export const useQueryGetAssignedRolesForUser = (
  { userId, orgId }: { userId: string; orgId: string },
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof client.getAssignedRolesForUser>>
  >
) => {
  return useQuery(
    [PERMISSIONS_QUERY_IDS.GET_ASSIGNED_ROLES_FOR_USER],
    () =>
      client.getAssignedRolesForUser(
        { userId },
        {},
        {
          headers: {
            'x-ivy-org-id': orgId
          }
        }
      ),
    { refetchOnWindowFocus: false, retry: false, ...options }
  )
}

export const useQueryGetAssignedRolesForUsers = (
  users: IUser[],
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof client.getAssignedRolesForUser>>[]
  >
) => {
  return useQuery(
    [PERMISSIONS_QUERY_IDS.GET_ASSIGNED_ROLES_FOR_USERS, users],
    () =>
      Promise.all(
        users.map((user) =>
          client.getAssignedRolesForUser(
            { userId: user.id },
            {},
            {
              headers: {
                'x-ivy-org-id': user.organization_id
              }
            }
          )
        )
      ),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: users.length > 0,
      ...options
    }
  )
}

export const useQueryGetAllRolesByOrgIds = (
  orgIds: string[],
  options?: UseQueryOptions<Awaited<ReturnType<typeof client.listAllRoles>>[]>
) => {
  return useQuery(
    [PERMISSIONS_QUERY_IDS.LIST_ALL_ROLES_BY_ORG_IDS, orgIds],
    () =>
      Promise.all(
        orgIds.map((orgId) =>
          client.listAllRoles(
            {},
            {},
            {
              headers: {
                'x-ivy-org-id': orgId
              }
            }
          )
        )
      ),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: orgIds?.length > 0,
      ...options
    }
  )
}

export const useMutationUpdateAssignedRoleForUser = (
  userId: string,
  orgId: string
) => {
  const queryClient = useQueryClient()

  return useMutation(
    (assignments: Assignments) => {
      return client.assignRoles({ userId }, assignments, {
        headers: { 'x-ivy-org-id': orgId }
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          PERMISSIONS_QUERY_IDS.GET_ASSIGNED_ROLES_FOR_USER
        )
      }
    }
  )
}
