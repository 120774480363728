import { useState } from 'react'

import { useUpdateEffect } from 'react-use'

import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
  DEFAULT_SORT,
  getConfig,
  TableConfigData,
  updateConfig
} from '~services'

type UseTableConfigData = TableConfigData
interface UseTableConfigActions {
  changePageSize: (pageSize: number) => void
  changePage: (newPage: number) => void
  changeSort: (newSort: string) => void
  resetTableConfig: () => void
}

export interface UseTableConfig {
  data: UseTableConfigData
  actions: UseTableConfigActions
}

export const useTableConfig = (tableKey: string): UseTableConfig => {
  const [page, setPage] = useState<number>(DEFAULT_PAGE_NUMBER)
  const [pageSize, setPageSize] = useState<number>(getConfig(tableKey).pageSize)

  const [sort, setSort] = useState<string>(getConfig(tableKey).sort)

  const columnSettings = getConfig(tableKey).columnSettings

  const {
    sort: tableSort,
    pageSize: tablePageSize,
    page: tablePage
  } = getConfig(tableKey)

  useUpdateEffect(() => {
    setSort(tableSort)
    setPageSize(tablePageSize)
    setPage(tablePage)
  }, [tableKey, tableSort, tablePageSize, tablePage])

  const changePageSize = (newPageSize: number) => {
    setPageSize(newPageSize)

    updateConfig(tableKey, {
      pageSize: newPageSize
    })
  }

  const changeSort = (newSort: string) => {
    setSort(newSort)

    updateConfig(tableKey, {
      sort: newSort
    })
  }

  const changePage = (newPage: number) => {
    setPage(newPage)

    updateConfig(tableKey, {
      page: newPage
    })
  }

  const resetTableConfig = () => {
    changePage(DEFAULT_PAGE_NUMBER)
    changePageSize(DEFAULT_PAGE_SIZE)
    changeSort(DEFAULT_SORT)
  }

  return {
    data: {
      page: page || DEFAULT_PAGE_NUMBER,
      pageSize: pageSize || DEFAULT_PAGE_SIZE,
      sort: sort ?? DEFAULT_SORT,
      columnSettings
    },
    actions: { changePageSize, changePage, changeSort, resetTableConfig }
  }
}
