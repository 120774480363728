import { API } from 'aws-amplify'
import { AxiosInstance } from 'axios'
import { LocalStorage } from 'ttl-localstorage'

interface AxiosClientOpts {
  baseURL?: string
  allowXOrgSharing?: boolean
  xEpilotOrgId?: string
}

const TOKEN_REFRESH_TTL = 900

const TOKEN_KEY = 'admin-portal-token'

export const configureClient = <ClientType extends AxiosInstance>(
  client: ClientType,
  opts: AxiosClientOpts = {}
) => {
  if (opts.baseURL) {
    client.defaults.baseURL = opts.baseURL
  }

  // add authorization header
  client.interceptors.request.use(async (config) => {
    let token = await LocalStorage.get(TOKEN_KEY)

    if (!token) {
      try {
        const res = await API.get('internal-auth', '/auth', {})

        token = res.token as string

        LocalStorage.put(TOKEN_KEY, token, TOKEN_REFRESH_TTL)
      } catch (error) {
        console.error(error)
      }
    }

    if (config.headers) {
      config.headers.authorization = `Bearer ${token}`
    }

    return config
  })

  return client
}
