import { useMemo } from 'react'

import { getClient, Components } from '@epilot/organization-client'

import { URL_CONFIG } from '~configs'

import { configureClient } from './utils.client'

export const getOrganizationClient = () => {
  const client = getClient()

  return configureClient(client, {
    baseURL: URL_CONFIG.ORGANIZATION
  })
}

export const useOrganizationClient = () =>
  useMemo(() => getOrganizationClient(), [])

export type Organization = Components.Schemas.Organization & {
  tags?: string[]
  status?: string
  pricing_tier_id?: string
}

export type OrganizationDataPoint = Components.Schemas.DataPoint
export type CreateOrganizationRequest =
  Components.Schemas.CreateOrganizationRequest
