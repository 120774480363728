import { Chip as MuiChip, DefaultEpilotTheme } from '@epilot/base-elements'
import styled from 'styled-components'

import { CLASS_PREFIX } from '~common'

interface Props {
  status: string
}

export function StatusChip(props: Props) {
  const { status } = props

  switch (status) {
    case 'Active':
      return (
        <StyledChip
          backgroundColor={DefaultEpilotTheme.palette.green.main}
          label={status}
          textColor={DefaultEpilotTheme.palette.green.contrastText}
        />
      )
    case 'Pending':
      return (
        <StyledChip
          backgroundColor={DefaultEpilotTheme.palette.orange.main}
          label={status}
          textColor={DefaultEpilotTheme.palette.orange.contrastText}
        />
      )
    case 'Deleted':
      return (
        <StyledChip
          backgroundColor={DefaultEpilotTheme.palette.red.main}
          label={status}
          textColor={DefaultEpilotTheme.palette.red.contrastText}
        />
      )
    default:
      return (
        <StyledChip
          backgroundColor={DefaultEpilotTheme.palette.green.main}
          label={status}
          textColor={DefaultEpilotTheme.palette.green.contrastText}
        />
      )
  }
}

const StyledChip = styled(MuiChip)<{
  textColor?: string
  backgroundColor?: string
}>`
  && {
    background-color: ${({ backgroundColor }) => backgroundColor || 'inherit'};
    .${CLASS_PREFIX}-MuiChip-label {
      color: ${({ textColor }) => textColor || 'inherit'};
    }

    * + & {
      margin-left: 0.2rem;
    }
  }
`

export const StatusWrapper = styled.div`
  display: flex;
`
