import React, { useEffect, useRef, useState } from 'react'

import {
  BodyText,
  Box,
  Button,
  Chip,
  CircularProgress,
  InlineSpacing
} from '@epilot/base-elements'
import { uniqueId } from 'lodash'
import { CSVLink } from 'react-csv'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { IOrganization, ITableColumn } from '~common'
import { TemplateSearchBar } from '~components'
import { useOrganizationTable } from '~hooks'
import { useDataPoints, usePartnership, usePricingTiers } from '~providers'

import { StatusChip } from '../../atoms'
import { MainTable } from '../../molecules'

export const OrganizationListPage = () => {
  const {
    isLoading: loadingOrganizations,
    data: paginatedOrganizations,
    originalData: organizations,
    dataCount,
    pagination,
    columns,
    suggestions,
    fieldValuesMap,
    setSearchAndFilterValues
  } = useOrganizationTable()
  const {
    setGetAllPartnershipsOrgIds,
    loadingGetAllPartnerships,
    partnershipDataPointMap
  } = usePartnership()

  const [isReadyExport, setIsReadyExport] = useState(false)
  const { loading: loadingDataPoints } = useDataPoints()
  const { loadingPricingTiers, loadingPricingTierAssignments } =
    usePricingTiers()

  const history = useHistory()
  const csvLinkRef = useRef(null)

  useEffect(() => {
    setGetAllPartnershipsOrgIds(paginatedOrganizations.map((org) => org?.id))
  }, [paginatedOrganizations, setGetAllPartnershipsOrgIds])

  useEffect(() => {
    setIsReadyExport(
      !loadingDataPoints &&
        !loadingOrganizations &&
        !loadingPricingTiers &&
        !loadingPricingTierAssignments &&
        !loadingGetAllPartnerships
    )
  }, [
    loadingDataPoints,
    loadingGetAllPartnerships,
    loadingOrganizations,
    loadingPricingTierAssignments,
    loadingPricingTiers
  ])

  const handleExportCSV = () => {
    setIsReadyExport(false)
    setGetAllPartnershipsOrgIds(organizations.map((org) => org?.id))
    csvLinkRef.current?.link.click()
    setIsReadyExport(true)
  }

  const renderTableCell = (
    column: ITableColumn,
    rowData: Record<string, unknown>
  ): React.ReactNode => {
    let component: React.ReactNode
    const partnershipDataPoint =
      partnershipDataPointMap?.[rowData?.id as string]

    switch (column?.field) {
      case 'status':
        component = (rowData as Record<string, unknown>)?.status ? (
          <Box display="flex">
            <StatusChip
              status={(rowData as Record<string, unknown>)?.status as string}
            />
          </Box>
        ) : null
        break
      case 'tags':
        component = (rowData as Record<string, unknown>)?.tags ? (
          <Tags color="primary">
            {(rowData?.tags as string[])?.map((tag) => (
              <InlineSpacing key={uniqueId('portal')} scale={1}>
                <Chip key={uniqueId('portal')} label={tag} />
              </InlineSpacing>
            ))}
          </Tags>
        ) : null
        break

      case 'new_pricing_tier':
        component =
          loadingPricingTiers ||
          loadingPricingTierAssignments ||
          loadingDataPoints ? (
            <CircularProgress size={12} />
          ) : (
            (rowData?.[column?.field] as React.ReactNode)
          )
        break

      default:
        if (column?.type == 'dataPoint') {
          component = loadingDataPoints ? (
            <CircularProgress size={12} />
          ) : (
            (rowData?.[column?.field] as React.ReactNode)
          )
          break
        }
        if (column?.type == 'partner') {
          component = loadingGetAllPartnerships ? (
            <CircularProgress size={12} />
          ) : (
            (partnershipDataPoint?.[column?.field] as React.ReactNode) || 0
          )
          break
        }

        component = rowData?.[column?.field] as React.ReactNode
        break
    }

    return component
  }

  return (
    <>
      <Box
        alignItems="center"
        display={'flex'}
        justifyContent={'space-between'}
        mb={2}
      >
        <Box display={'flex'} width="50%">
          <TemplateSearchBar
            fieldValuesMap={fieldValuesMap}
            onChange={setSearchAndFilterValues}
            suggestions={suggestions}
          />
        </Box>
        <Box display={'flex'} flexDirection="row">
          <Button
            color="primary"
            component={Link}
            style={{ marginRight: 8 }}
            to={'/organizations/create'}
            variant="contained"
          >
            Create
          </Button>
          <Button
            color="primary"
            disabled={!isReadyExport}
            onClick={handleExportCSV}
            variant="contained"
          >
            Export
            {!isReadyExport ? (
              <CircularProgress size={16} style={{ marginLeft: 10 }} />
            ) : (
              ''
            )}
          </Button>
        </Box>
      </Box>
      <CSVLink
        className="hidden"
        data={organizations.map((org) => {
          let tmpOrg = {}
          const partnershipDataPoint = partnershipDataPointMap?.[org?.id]

          columns.forEach((column) => {
            if (column.type === 'partner') {
              tmpOrg = {
                ...tmpOrg,
                [column.field]: partnershipDataPoint?.[column.field] || 0
              }
            } else {
              tmpOrg = {
                ...tmpOrg,
                [column.field]: org[column.field]
              }
            }
          })

          return tmpOrg
        })}
        filename={`organizations-${new Date().toISOString()}.csv`}
        ref={csvLinkRef}
        target="_blank"
      />
      <Box display="flex" flexDirection="column" height={'100%'} width={'100%'}>
        <MainTable
          {...pagination}
          columns={columns}
          customRenderTableCell={renderTableCell}
          data={paginatedOrganizations}
          dataCount={dataCount}
          isLoading={loadingOrganizations}
          locale={''}
          onRowClick={(row: IOrganization) => {
            history.push('/organizations/' + row?.id)
          }}
        />
      </Box>
    </>
  )
}

const Tags = styled(BodyText)`
  > a {
    margin-right: 0;
    padding-right: 0;
  }
  > a + a:before {
    display: inline-block;
    content: ', ';
  }
`
