import { createContext, useContext } from 'react'

import _ from 'lodash'

import { useQueryGetOrganizationTags, useQueryGetUserTags } from '~services'

export interface TagContext {
  organizationTags: string[]
  userTags: string[]
  loadingUserTags: boolean
}

const TagsContext = createContext<Partial<TagContext>>({})

export const TagsContextProvider = (props: { children: JSX.Element }) => {
  const orgTagsQueryResp = useQueryGetOrganizationTags()

  const userTagsQueryResp = useQueryGetUserTags()

  return (
    <TagsContext.Provider
      value={{
        organizationTags: _.chain(orgTagsQueryResp?.data?.data || []).value(),
        userTags: userTagsQueryResp?.data?.data ?? [],
        loadingUserTags: userTagsQueryResp.isFetching
      }}
    >
      {props.children}
    </TagsContext.Provider>
  )
}

export const useTags = () => useContext(TagsContext)
