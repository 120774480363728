import { useMemo } from 'react'

import { getClient, Components } from '@epilot/user-client'

import { URL_CONFIG } from '~configs'

import { configureClient } from './utils.client'

export const getUserClient = () => {
  const client = getClient()

  return configureClient(client, { baseURL: URL_CONFIG.USER })
}

export const useUserClient = () => useMemo(() => getUserClient(), [])

export type { Components, Paths } from '@epilot/user-client'

export type User = Components.Schemas.UserV2
export type UserDataPoint = Components.Schemas.DataPoint
