import React, { useMemo, useEffect, useRef, useState } from 'react'

import type { JsonEditorProps } from 'svelte-jsoneditor/components/JSONEditor.svelte'
import { JSONEditor, MenuItem } from 'svelte-jsoneditor/dist/jsoneditor.js'

import './SvelteJSONEditor.css'

export const SvelteJSONEditor = (props: JsonEditorProps) => {
  const refContainer = useRef<HTMLDivElement | null>(null)
  const refEditor = useRef<JSONEditor | null>(null)
  const [mode, setMode] = useState(props.mode ?? 'tree')

  const editorProps: JsonEditorProps = useMemo(() => {
    return {
      onRenderMenu: minimalMenu,
      ...props,
      mode: props.mode ?? mode,
      onChangeMode: setMode
    }
  }, [mode, setMode, props])

  useEffect(() => {
    // create editor
    if (!refEditor.current) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      refEditor.current = new (JSONEditor as any)({
        target: refContainer.current,
        props: editorProps
      })
    }

    return () => {
      if (refEditor.current) {
        refEditor.current.destroy()
        refEditor.current = null
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (refEditor.current) {
      refEditor.current.updateProps(editorProps)
    }
  }, [editorProps])

  return <div className="svelte-json-editor flex" ref={refContainer} />
}

const minimalMenu = (_mode: string, items: MenuItem[]) =>
  items.filter(
    (item) => !['jse-transform', 'jse-sort'].includes(item['className'])
  )
