import { useState } from 'react'

import {
  Box,
  createStyles,
  makeStyles,
  PrimaryTabs,
  Tab,
  TabContext,
  TabPanel
} from '@epilot/base-elements'
import { useParams } from 'react-router'

import { PricingTier } from '~apis'
import { useQueryGetPricingTierById } from '~services'

import { PricingTierInfo } from '../organisms/PricingTierInfo'

export enum TABS {
  PRICING_TIER_DETAILS = 'PRICING_TIER_DETAILS'
}

const useStyles = makeStyles(() =>
  createStyles({
    tabPanel: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1
    },
    input: {
      height: 44
    }
  })
)

export const UpdatePricingTierPage = () => {
  const classes = useStyles()
  const [currentTab, setCurrentTab] = useState<TABS>(TABS.PRICING_TIER_DETAILS)
  const handleTabChange = (_, newValue: TABS) => {
    setCurrentTab(newValue)
  }
  const [pricingTier, setPricingTier] = useState(null)
  const [editingPricingTier, setEditingPricingTier] = useState(null)

  const { id } = useParams<{ id: string }>()

  const { isFetching } = useQueryGetPricingTierById(id, {
    enabled: Boolean(id),
    onSettled(data, error) {
      if (error) return
      setPricingTier(data?.data as PricingTier)
      setEditingPricingTier(data?.data as PricingTier)
    }
  })

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      style={{ height: '100%' }}
    >
      <TabContext value={currentTab}>
        <PrimaryTabs
          aria-label="simple tabs example"
          indicatorColor="primary"
          onChange={handleTabChange as never}
        >
          <Tab
            label={'Pricing tier details'}
            value={TABS.PRICING_TIER_DETAILS}
          />
        </PrimaryTabs>
        <TabPanel
          className={
            currentTab === TABS.PRICING_TIER_DETAILS
              ? classes.tabPanel
              : undefined
          }
          value={TABS.PRICING_TIER_DETAILS}
        >
          <Box display="flex" flexDirection="column" py={3}>
            <PricingTierInfo
              editingPricingTier={editingPricingTier}
              isLoading={isFetching}
              originalPricingTier={pricingTier}
              setEditingPricingTier={setEditingPricingTier}
            />
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  )
}
