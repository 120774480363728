/* eslint-disable import/no-duplicates */
import format from 'date-fns/format'
import de from 'date-fns/locale/de'
import flatten from 'lodash/flatten'
import isArray from 'lodash/isArray'
import mergeWith from 'lodash/mergeWith'
import uniq from 'lodash/uniq'

import { SortDirection } from './enums'
import { SearchSuggestion, SortModel } from './types'

const normalizeToDate = (input: unknown) => {
  if (input instanceof Date) return input

  return new Date(input as string)
}

export const formatDate = (input: unknown, localeCode = 'en') => {
  if (!input) return ''
  if (isNaN(Date.parse(input as string))) return ''

  try {
    const normalizedDate = normalizeToDate(input)

    if (normalizedDate.toString() === 'Invalid Date') {
      return normalizedDate.toString()
    }

    return format(normalizedDate, 'dd.MM.y', {
      locale: localeCode === 'en' ? undefined : de
    })
  } catch (err) {
    return ''
  }
}

export const formatDateTime = (input: unknown, locale = 'en') => {
  if (!input) return ''
  if (isNaN(Date.parse(input as string))) return ''

  try {
    return format(normalizeToDate(input), 'dd.MM.y HH:mm', {
      locale: locale === 'en' ? undefined : de
    })
  } catch (err) {
    return ''
  }
}

export const splitSortIntoFieldAndDirection = (sort: string): SortModel => {
  if (!sort) {
    return {
      field: 'filename',
      direction: SortDirection.ASCENDING
    }
  }
  const [field, direction] = sort.split(':')

  return {
    field,
    direction: (direction as SortDirection) || SortDirection.ASCENDING
  }
}

export const getNewSortDirection = (
  isSameField: boolean,
  currentSortDirection: SortDirection
): SortDirection => {
  return isSameField && currentSortDirection === SortDirection.ASCENDING
    ? SortDirection.DESCENDING
    : SortDirection.ASCENDING
}

export const yn = (input: string): boolean => {
  const value = input.trim().toLowerCase()

  if (/^(?:n|no|nein|false|0)$/i.test(value)) {
    return false
  }

  return Boolean(value)
}

function customizer(objValue, srcValue) {
  if (isArray(objValue)) {
    return uniq(flatten(objValue.concat(srcValue)))
  }
}

export const parseFieldValuesSuggestionMap = <T>(
  data: T[],
  enableFilteringFields: string[]
) => {
  return data?.reduce((acc, it) => {
    const tmp = {}

    enableFilteringFields.forEach((field) => {
      if (it[field] != null) {
        switch (field) {
          case '360_roles':
            if (it[field]?.length > 0) {
              const roleNames = it[field]
                .filter(Boolean)
                .map((elem) => elem.name)

              tmp[field] = Array.isArray(tmp[field])
                ? tmp[field].push(...roleNames)
                : [...roleNames]
            }
            break
          case 'organization':
            if (it[field]) {
              tmp[field] = Array.isArray(tmp[field])
                ? tmp[field].push(it[field].name)
                : [it[field].name]
            }
            break
          default:
            tmp[field] = Array.isArray(tmp[field])
              ? tmp[field].push(it[field])
              : [it[field]]
        }
      }
    })

    return mergeWith(acc, tmp, customizer)
  }, {})
}

export const parseSearchAndFilterSuggestion = (
  searchAndFilterValues: SearchSuggestion[]
) => {
  const filterKeyValueMap = {}
  let searchValue = null

  searchAndFilterValues.forEach((searchAndFilterValue) => {
    if (searchAndFilterValue.type === 'filter') {
      const [filterKey, filterValue] = (searchAndFilterValue.value ?? '').split(
        ':'
      )

      if (filterKey && filterValue) {
        filterKeyValueMap[filterKey] = filterValue
      }
    }

    if (searchAndFilterValue.type === 'search') {
      searchValue = searchAndFilterValue.value
    }
  })

  return { filterKeyValueMap, searchValue }
}

export const isValidJSON = (json: string) => {
  try {
    JSON.parse(json)

    return true
  } catch (e) {
    return false
  }
}
