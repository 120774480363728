import { Authenticator } from '@aws-amplify/ui-react'
import { ThemeProvider, DefaultEpilotTheme } from '@epilot/base-elements'
import { Amplify } from 'aws-amplify'
import { SnackbarProvider } from 'notistack'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter as Router } from 'react-router-dom'

import { CLASS_PREFIX } from '~common'
import { awsConfig } from '~configs'
import {
  DataPointsContextProvider,
  JSONEditorDrawerContextProvider,
  TagsContextProvider,
  RoleAndPermissionContextProvider,
  PartnershipContextProvider
} from '~providers'

import { PricingTierContextProvider } from './providers/PricingTierContextProvider'
import Routes from './routes/Routes'

import '@aws-amplify/ui-react/styles.css'

Amplify.configure(awsConfig)

const client = new QueryClient()

const App: React.FC = () => {
  return (
    <Authenticator.Provider>
      <QueryClientProvider client={client}>
        <ThemeProvider classPrefix={CLASS_PREFIX} theme={DefaultEpilotTheme}>
          <SnackbarProvider>
            <RoleAndPermissionContextProvider>
              <DataPointsContextProvider>
                <PricingTierContextProvider>
                  <PartnershipContextProvider>
                    <TagsContextProvider>
                      <JSONEditorDrawerContextProvider>
                        <Router>
                          <Routes />
                        </Router>
                      </JSONEditorDrawerContextProvider>
                    </TagsContextProvider>
                  </PartnershipContextProvider>
                </PricingTierContextProvider>
              </DataPointsContextProvider>
            </RoleAndPermissionContextProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </Authenticator.Provider>
  )
}

export default App
