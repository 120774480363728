import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react'
import { Box, Button } from '@epilot/base-elements'
import { Auth } from 'aws-amplify'
import { Redirect, Route, Switch } from 'react-router-dom'

import {
  CreateOrganizationPage,
  MainTemplate,
  OrganizationListPage,
  UpdateOrganizationPage,
  UpdateUserPage,
  UserListPage,
  PricingTierListPage,
  UpdatePricingTierPage,
  CreatePricingTierPage
} from '~components'

import {
  BASE_URL,
  CREATE_ORGANIZATION_URL,
  ORGANIZATION_LIST_URL,
  UPDATE_ORGANIZATION_URL,
  UPDATE_USER_URL,
  USER_URL,
  PRICING_TIER_URL,
  UPDATE_PRICING_TIER_URL,
  CREATE_PRICING_TIER_URL
} from './RouteUtils'

const Routes = () => {
  const { user } = useAuthenticator((context) => [context?.user])

  return (
    <Box>
      {user ? (
        <MainTemplate>
          <Switch>
            <Route exact path={BASE_URL}>
              <Redirect to={ORGANIZATION_LIST_URL} />
            </Route>
            <Route
              component={OrganizationListPage}
              exact
              path={ORGANIZATION_LIST_URL}
            />
            <Route component={UserListPage} exact path={USER_URL} />
            <Route component={UpdateUserPage} path={UPDATE_USER_URL} />
            <Route
              component={CreateOrganizationPage}
              exact
              path={CREATE_ORGANIZATION_URL}
            />
            <Route
              component={UpdateOrganizationPage}
              path={UPDATE_ORGANIZATION_URL}
            />
            <Route
              component={PricingTierListPage}
              exact
              path={PRICING_TIER_URL}
            />
            <Route
              component={CreatePricingTierPage}
              exact
              path={CREATE_PRICING_TIER_URL}
            />
            <Route
              component={UpdatePricingTierPage}
              path={UPDATE_PRICING_TIER_URL}
            />
          </Switch>
        </MainTemplate>
      ) : (
        <Box
          alignItems="center"
          display="flex"
          flex={1}
          flexDirection="column"
          height={'100vh'}
          justifyContent={'center'}
        >
          <Box mb={2}>
            <Button
              color="primary"
              onClick={() => Auth.federatedSignIn()}
              size="large"
              variant="contained"
            >
              Sign in with AWS
            </Button>
          </Box>
          <Authenticator hideSignUp={true} />
        </Box>
      )}
    </Box>
  )
}

export default Routes
