import { createContext, useContext, useEffect, useState } from 'react'

import filter from 'lodash/filter'
import groupBy from 'lodash/groupBy'

import { OrganizationDataPoint, UserDataPoint } from '~apis'
import { IDataPoint } from '~common'
import {
  useQueryGetOrganizationDataPoints,
  useQueryGetUserDataPoints
} from '~services'

export interface DataPointsContext {
  mergedDataPointMap: Record<string, IDataPoint>
  loading: boolean
}

const DataPointsContext = createContext<DataPointsContext>({
  mergedDataPointMap: {},
  loading: false
})

export const DataPointsContextProvider = (props: { children: JSX.Element }) => {
  const orgDataPointsQueryResp = useQueryGetOrganizationDataPoints()
  const userDataPointsQueryResp = useQueryGetUserDataPoints()

  const [mergedDataPointMap, setMergeDataPointMap] = useState<
    Record<string, IDataPoint>
  >({})

  useEffect(() => {
    if (
      userDataPointsQueryResp?.data?.data &&
      orgDataPointsQueryResp?.data?.data
    ) {
      const userDataPointMap = groupBy(
        filter(
          userDataPointsQueryResp.data.data as IDataPoint[],
          (elem) => elem.id
        ),
        'id'
      )
      const orgDataPointMap = groupBy(
        filter(
          orgDataPointsQueryResp.data.data as IDataPoint[],
          (elem) => elem.id
        ),
        'id'
      )

      let mergedDataPointMap: Record<string, IDataPoint> = {}

      Object.entries(userDataPointMap).forEach(([orgId, userDataPoints]) => {
        const userDataPoint = userDataPoints[0] as UserDataPoint
        const orgDataPoint = orgDataPointMap[
          orgId
        ]?.[0] as OrganizationDataPoint

        const actual_users = userDataPoint?.actual_users ?? 0
        const max_users_last_month = userDataPoint?.max_users_last_month ?? 0
        const non_billable_users_last_month =
          userDataPoint?.non_billable_users_last_month ?? 0
        const max_customer = orgDataPoint?.max_customer ?? 0
        const actual_customer = orgDataPoint?.actual_customer ?? 0

        mergedDataPointMap = {
          ...mergedDataPointMap,
          [orgId]: {
            actual_users,
            max_users_last_month,
            non_billable_users_last_month,
            max_customer,
            actual_customer
          }
        }
      })
      setMergeDataPointMap(mergedDataPointMap)
    }
  }, [userDataPointsQueryResp?.data?.data, orgDataPointsQueryResp?.data?.data])

  return (
    <DataPointsContext.Provider
      value={{
        loading:
          orgDataPointsQueryResp.isFetching ||
          userDataPointsQueryResp.isFetching,
        mergedDataPointMap
      }}
    >
      {props.children}
    </DataPointsContext.Provider>
  )
}

export const useDataPoints = () => useContext(DataPointsContext)
