export const URL_CONFIG = {
  ADMIN_PORTAL:
    process.env.REACT_APP_ADMIN_PORTAL_URL ||
    'https://admin-portal.dev.sls.epilot.io',
  API_INTERNAL_AUTH:
    process.env.REACT_APP_API_INTERNAL_AUTH ||
    'https://h2d2syd0a4.execute-api.eu-central-1.amazonaws.com/dev/v1/internal-auth',
  USER: process.env.REACT_APP_API_USER ?? 'https://user.dev.sls.epilot.io',
  PERMISSIONS:
    process.env.REACT_APP_API_PERMISSIONS ??
    'https://permissions.dev.sls.epilot.io',
  ORGANIZATION:
    process.env.REACT_APP_API_ORGANIZATION ??
    'https://organization-v2.dev.sls.epilot.io',
  PRICING_TIER:
    process.env.REACT_APP_API_PRICING_TIER ??
    'https://mdxfvzafh7.execute-api.eu-central-1.amazonaws.com',
  ENTITY: process.env.REACT_APP_API_ENTITY ?? 'https://entity.dev.sls.epilot.io'
}
