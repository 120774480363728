import { createContext, useContext, useState } from 'react'

import { difference, uniq } from 'lodash'

import { useQueryGetAllActivePartners } from '~services'

import { useDataPoints } from './DataPointContextProvider'

export interface PartnershipContext {
  loadingGetAllPartnerships: boolean
  setGetAllPartnershipsOrgIds: React.Dispatch<React.SetStateAction<string[]>>
  partnershipDataPointMap: Record<string, PartnershipDataPoint>
}
export interface PartnershipDataPoint {
  active_partners?: number
  actual_partner_users?: number
  max_partner_users?: number
}

const PartnershipContext = createContext<PartnershipContext>({
  loadingGetAllPartnerships: false,
  setGetAllPartnershipsOrgIds: () => [],
  partnershipDataPointMap: {}
})

export const PartnershipContextProvider: React.FC = ({ children }) => {
  const [partnershipDataPointMap, setPartnershipDataPointMap] = useState<
    Record<string, PartnershipDataPoint>
  >({})

  const [getAllPartnershipsOrgIds, setGetAllPartnershipsOrgIds] = useState<
    string[]
  >([])
  const [cachedGetAllPartnershipOrgIds, setCachedGetAllPartnershipOrgIds] =
    useState<string[]>([])
  const { mergedDataPointMap } = useDataPoints()

  const getAllPartnershipsByOrgIdsQuery = useQueryGetAllActivePartners(
    uniq(difference(getAllPartnershipsOrgIds, cachedGetAllPartnershipOrgIds)),
    {
      onSuccess(responses) {
        for (const response of responses) {
          const results = response?.data?.results
          const partnershipDataPoint = {
            active_partners: results?.length || 0,
            actual_partner_users: 0,
            max_partner_users: 0
          } as PartnershipDataPoint

          if (results?.length > 0) {
            const orgId = results?.[0]?._org

            results?.map((partnership) => {
              if (
                partnership?.partner_org_id &&
                mergedDataPointMap[partnership?.partner_org_id]
              ) {
                partnershipDataPoint.actual_partner_users +=
                  mergedDataPointMap[partnership?.partner_org_id]?.actual_users
                partnershipDataPoint.max_partner_users +=
                  mergedDataPointMap[
                    partnership?.partner_org_id
                  ]?.max_users_last_month
              }
            })
            partnershipDataPointMap[orgId] = partnershipDataPoint
            setPartnershipDataPointMap(partnershipDataPointMap)
          }
        }

        setCachedGetAllPartnershipOrgIds((prevState) =>
          uniq([
            ...prevState,
            ...difference(
              getAllPartnershipsOrgIds,
              cachedGetAllPartnershipOrgIds
            )
          ])
        )
      }
    }
  )

  return (
    <PartnershipContext.Provider
      value={{
        loadingGetAllPartnerships: getAllPartnershipsByOrgIdsQuery.isLoading,
        setGetAllPartnershipsOrgIds,
        partnershipDataPointMap
      }}
    >
      {children}
    </PartnershipContext.Provider>
  )
}

export const usePartnership = () => useContext(PartnershipContext)
