import { useCallback, useEffect, useState } from 'react'

import orderBy from 'lodash/orderBy'
import { useParams } from 'react-router'
import { useUpdateEffect } from 'react-use'

import { Role } from '~apis'
import { ITableColumn } from '~common'
import { useQueryGetAllRoles } from '~services'

import { useTableConfig } from './useTableConfig'

const columns: ITableColumn[] = [
  {
    field: 'id',
    displayName: 'Id',
    align: 'left',
    width: 200
  },
  {
    field: 'name',
    displayName: 'Name',
    align: 'left',
    width: 200
  },
  {
    field: 'type',
    displayName: 'Role type',
    align: 'left',
    width: 200
  }
]

export const useRoleTable = () => {
  const [roles, setRoles] = useState<Role[]>([])
  const [filteredRoles, setFilteredRoles] = useState<Role[]>([])
  const [paginatedRoles, setPaginatedRoles] = useState<Role[]>([])

  const { id } = useParams<{
    id: string
  }>()

  const { isLoading, data } = useQueryGetAllRoles({ orgId: id })

  useEffect(() => {
    if (data?.data) {
      setRoles(data.data.roles)
      setFilteredRoles(data.data.roles)
    }
  }, [data?.data])

  const {
    data: { pageSize, page, sort },
    actions: { changePage, changePageSize, changeSort }
  } = useTableConfig('admin-portal-roles')

  // sort role table column
  useUpdateEffect(() => {
    if (sort.split(':')?.length > 0) {
      type SortOrder = boolean | 'asc' | 'desc'
      const [field, order] = sort.split(':')

      setFilteredRoles((prevState) => {
        return orderBy(prevState, [field], [order as SortOrder])
      })
    }
  }, [sort])

  // new search results, reset current page to 1
  useUpdateEffect(() => {
    setPaginatedRoles(filteredRoles.slice(0, pageSize))
    changeCurrentPage(1)
  }, [filteredRoles])

  useUpdateEffect(() => {
    setPaginatedRoles(
      filteredRoles.slice((page - 1) * pageSize, page * pageSize)
    )
  }, [pageSize, page])

  const changeCurrentPage = useCallback(
    (newPage: number) => {
      changePage(newPage)
    },
    [changePage]
  )

  const changeCurrentPageSize = useCallback(
    (pageSize: number) => {
      changePageSize(pageSize)
    },
    [changePageSize]
  )

  const changeCurrentSort = useCallback(
    (sort: string) => {
      changeSort(sort)
    },
    [changeSort]
  )

  return {
    data: paginatedRoles,
    dataCount: roles?.length,
    isLoading: isLoading,
    columns,
    pagination: {
      currentPage: page,
      currentPageSize: pageSize,
      currentSort: sort,
      changeCurrentPage,
      changeCurrentPageSize,
      changeCurrentSort
    }
  }
}
