import { useMutation, useQuery, UseQueryOptions } from 'react-query'

import { CreateOrganizationRequest, getOrganizationClient } from '~apis'
import { IOrganization } from '~common'

export enum ORGANIZATION_QUERY_IDS {
  GET_ORGANIZATION = 'GET_ORGANIZATION',
  GET_ORGANIZATIONS = 'GET_ORGANIZATIONS',
  GET_DATA_POINTS = 'GET_DATA_POINTS',
  GET_ORGANIZATION_TAGS = 'GET_ORGANIZATION_TAGS'
}

const client = getOrganizationClient()

export const useQueryGetOrganization = (
  { orgId }: { orgId: string },
  options?: UseQueryOptions<Awaited<ReturnType<typeof client.getOrganization>>>
) => {
  return useQuery(
    [ORGANIZATION_QUERY_IDS.GET_ORGANIZATION, orgId],
    () =>
      client.getOrganization({
        org_id: orgId
      }),
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      ...options
    }
  )
}

export const useMutationUpdateOrganization = () => {
  return useMutation((organization: IOrganization) => {
    return client.updateOrganization(organization?.id, organization)
  })
}

export const useQueryGetOrganizations = (
  opts?: UseQueryOptions<Awaited<ReturnType<typeof client.getOrganizations>>>
) => {
  return useQuery(
    [ORGANIZATION_QUERY_IDS.GET_ORGANIZATIONS],
    () => client.getOrganizations({ limit: '1000' }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      ...opts
    }
  )
}

export const useQueryGetOrganizationDataPoints = (
  opts?: UseQueryOptions<Awaited<ReturnType<typeof client.getDataPoints>>>
) => {
  return useQuery(
    [ORGANIZATION_QUERY_IDS.GET_DATA_POINTS],
    () => client.getDataPoints(),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
      ...opts
    }
  )
}

export const useQueryGetOrganizationTags = (
  opts?: UseQueryOptions<Awaited<ReturnType<typeof client.getOrganizationTags>>>
) => {
  return useQuery(
    [ORGANIZATION_QUERY_IDS.GET_ORGANIZATION_TAGS],
    () => client.getOrganizationTags(),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
      ...opts
    }
  )
}

export const useMutationCreateOrganization = () => {
  return useMutation((createOrganizationRequest: CreateOrganizationRequest) => {
    return client.createOrganization({}, createOrganizationRequest)
  })
}
