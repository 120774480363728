import { useMemo } from 'react'

import { Components, getClient } from '@epilot/permissions-client'

import { URL_CONFIG } from '~configs'

import { configureClient } from './utils.client'

export const getPermissionsClient = () => {
  const client = getClient()

  return configureClient(client, {
    baseURL: URL_CONFIG.PERMISSIONS
  })
}

export const usePermissionClient = () =>
  useMemo(() => getPermissionsClient(), [])

export type Role = Components.Schemas.Role
export type PartnerRole = Components.Schemas.PartnerRole
export type RolePayload = Components.Schemas.RolePayload
export type Assignment = Components.Schemas.Assignment
export type Assignments = Components.Schemas.Assignments
export type Grant = Components.Schemas.Grant
export type GrantWithDependencies = Components.Schemas.GrantWithDependencies
export type OrgAssignments = Components.Schemas.OrgAssignments
export type OrgRoles = Components.Schemas.OrgRoles
