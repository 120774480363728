import { useEffect, useState } from 'react'

import { useAuthenticator } from '@aws-amplify/ui-react'
import {
  AutocompleteX,
  Box,
  Button,
  CircularProgress,
  Icon,
  makeStyles,
  TextField
} from '@epilot/base-elements'
import Alert from '@material-ui/lab/Alert'
import _ from 'lodash'

import { PricingTier } from '~apis'
import { formatDateTime, IDataPoint, IOrganization } from '~common'
import { useTags } from '~providers'
import {
  useMutationAssignPricingTier,
  useMutationUpdateOrganization,
  useMutationUpdatePricingTier,
  useQueryGetActivePartnerByOrgId,
  useQueryGetAssignedPricingTierForOrganization,
  useQueryGetPricingTierByOrganizationId,
  useQueryGetPricingTiers,
  useQueryUsersByOrgId
} from '~services'

import { PricingTierContent } from './PricingTierContent'

const useStyles = makeStyles({
  container: {
    marginTop: 20,
    width: '50%',
    '& > div:not(:first-child)': {
      marginTop: 30
    }
  }
})

type Props = {
  orgInfo: IOrganization
  setOrganization: React.Dispatch<React.SetStateAction<IOrganization>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataPoint: any
  loadingDataPoint: boolean
  mergedDataPointMap: Record<string, IDataPoint>
}

export const OrganizationInfo = (props: Props) => {
  const classes = useStyles()

  const { orgInfo, dataPoint, setOrganization, loadingDataPoint } = props

  const [inputValue, setInputValue] = useState('')
  const [additionalPaidUsers, setAdditionalPaidUsers] = useState(0)
  const [maxBillableUsersLastMonth, setMaxBillableUsersLastMonth] = useState(0)
  const { organizationTags } = useTags()
  const [values, setValues] = useState([])
  const [pricingTierInputValue, setPricingTierInputValue] = useState('')
  const [pricingTierValues, setPricingTierValues] = useState(null)
  const [enabledUpdateBtn, setEnabledUpdateBtn] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [originalPricingTier, setOriginalPricingTier] = useState(null)
  const [editingPricingTier, setEditingPricingTier] =
    useState<PricingTier>(null)
  const [selectedPricingTierId, setSelectedPricingTierId] = useState(null)
  const [activePartners, setActivePartners] = useState([])
  const { data: pricingTierData } = useQueryGetPricingTiers()
  const { data: assignedPricingTier } =
    useQueryGetAssignedPricingTierForOrganization(orgInfo?.id, {
      enabled: Boolean(orgInfo?.id)
    })
  const { user } = useAuthenticator((context) => [context?.user])

  useEffect(() => {
    if (assignedPricingTier?.data?.pricing_tier_id) {
      setSelectedPricingTierId(assignedPricingTier?.data?.pricing_tier_id)
    }
  }, [assignedPricingTier?.data?.pricing_tier_id])

  useQueryGetPricingTierByOrganizationId(selectedPricingTierId, orgInfo?.id, {
    enabled: Boolean(selectedPricingTierId) && Boolean(orgInfo?.id),
    onSettled(data, error) {
      if (error) return
      if (selectedPricingTierId == assignedPricingTier?.data?.pricing_tier_id) {
        setOriginalPricingTier(data?.data as PricingTier)
      }
      setEditingPricingTier(data?.data as PricingTier)
    }
  })
  useQueryGetActivePartnerByOrgId(orgInfo?.id, {
    enabled: Boolean(orgInfo?.id),
    onSettled(data, error) {
      if (error) return
      setActivePartners(data?.data?.results)
    }
  })

  const endCustomerPortalUserQuery = useQueryUsersByOrgId(
    orgInfo?.id,
    'END_CUSTOMER_PORTAL',
    {
      enabled: Boolean(orgInfo?.id),
      onSettled(data, error) {
        if (error) return
        setActivePartners(data?.data?.results)
      }
    }
  )

  const installerPortalUserQuery = useQueryUsersByOrgId(
    orgInfo?.id,
    'INSTALLER_PORTAL',
    {
      enabled: Boolean(orgInfo?.id),
      onSettled(data, error) {
        if (error) return
        setActivePartners(data?.data?.results)
      }
    }
  )

  useEffect(() => {
    setEnabledUpdateBtn(!_.isEqual(originalPricingTier, editingPricingTier))
  }, [originalPricingTier, editingPricingTier])

  const updateOrganization = useMutationUpdateOrganization()
  const updateOrganizationPricingTier = useMutationUpdatePricingTier(
    orgInfo?.id
  )
  const assignPricingTier = useMutationAssignPricingTier()

  useEffect(() => {
    if (orgInfo) {
      setValues(orgInfo?.['tags'])
    }
    if (orgInfo && editingPricingTier) {
      setPricingTierValues(editingPricingTier)
    }
    if (editingPricingTier && !loadingDataPoint) {
      setMaxBillableUsersLastMonth(
        dataPoint?.non_billable_users_last_month > 0
          ? dataPoint?.max_users_last_month -
              dataPoint?.non_billable_users_last_month
          : dataPoint?.max_users_last_month
      )
      const freeUserLimit = editingPricingTier?.override_settings?.free_user
        ? editingPricingTier?.override_settings?.free_user?.quota
        : editingPricingTier?.settings?.free_user?.quota || 0

      if (+freeUserLimit > 0 && maxBillableUsersLastMonth - freeUserLimit > 0) {
        setAdditionalPaidUsers(maxBillableUsersLastMonth - freeUserLimit)
      }
    }
  }, [
    orgInfo,
    editingPricingTier,
    maxBillableUsersLastMonth,
    loadingDataPoint,
    dataPoint?.non_billable_users_last_month,
    dataPoint?.max_users_last_month
  ])

  const handleOrgChange =
    (path: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setEnabledUpdateBtn(true)
      const value = e.target.value

      setOrganization((prevState) => {
        const newConfig = _.cloneDeep(prevState)

        _.set(newConfig, path, value)

        return newConfig
      })
    }

  const handleUpdateOrganization = () => {
    if (editingPricingTier?.id) {
      assignPricingTier.mutate(
        {
          organization_id: orgInfo?.id,
          pricing_tier_id: editingPricingTier?.id
        },
        {
          onError: (err) => {
            setErrorMessage(
              `Error while assign pricing tier. ${err?.['message']}`
            )
          }
        }
      )

      updateOrganizationPricingTier.mutate(
        { ...editingPricingTier, updated_by: user?.attributes?.email || '' },
        {
          onError: (err) => {
            setErrorMessage(
              `Error while updating pricing tier. ${err?.['message']}`
            )
          }
        }
      )
    }

    updateOrganization.mutate(
      {
        ...orgInfo,
        tags: values,
        pricing_tier_id: pricingTierValues?.['id']
      },
      {
        onSuccess: () => {
          setEnabledUpdateBtn(false)
          setErrorMessage('')
        },
        onError: (err) => {
          setErrorMessage(
            `Error while updating organization. ${err?.['message']}`
          )
        }
      }
    )
  }

  const getPricingTierName = (editingPricingTier?: PricingTier) =>
    editingPricingTier?.name ||
    pricingTierData?.data?.pricing_tiers.find(
      (p) => p.id == editingPricingTier.id
    )?.name ||
    editingPricingTier?.id

  const getActualPartnerUsers = () => {
    let actualPartnerUsers = 0

    if (!loadingDataPoint) {
      for (const activePartner of activePartners) {
        if (activePartner?.partner_org_id) {
          actualPartnerUsers +=
            props.mergedDataPointMap[activePartner?.partner_org_id]
              ?.actual_users
        }
      }
    }

    return actualPartnerUsers
  }

  const getMaxPartnerUsers = () => {
    let maxPartnerUsers = 0

    if (!loadingDataPoint) {
      for (const activePartner of activePartners) {
        if (activePartner?.partner_org_id) {
          maxPartnerUsers +=
            props.mergedDataPointMap[activePartner?.partner_org_id]
              ?.max_users_last_month
        }
      }
    }

    return maxPartnerUsers
  }

  return (
    <>
      {!orgInfo?.id && <CircularProgress />}
      {orgInfo?.id && (
        <>
          <Box display="flex" flexDirection="row">
            <Box
              className={classes.container}
              display="flex"
              flexDirection="column"
            >
              <TextField
                disabled
                label="Id"
                value={orgInfo?.id}
                variant="standard"
              />

              <TextField
                label="Name"
                onChange={handleOrgChange('name')}
                value={orgInfo.name}
                variant="standard"
              />
              <TextField
                disabled
                label="Symbol"
                value={orgInfo?.symbol}
                variant="standard"
              />
              <TextField
                label="Email"
                onChange={handleOrgChange('email')}
                value={orgInfo.email}
                variant="standard"
              />
              <TextField
                disabled
                label="Type"
                value={orgInfo.type}
                variant="standard"
              />
              <TextField
                disabled
                label="Status"
                value={orgInfo?.status}
                variant="standard"
              />
              {orgInfo?.type != 'Partner' && (
                <>
                  <AutocompleteX
                    freeSolo={false}
                    getOptionLabel={getPricingTierName}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    inputValue={pricingTierInputValue}
                    onChange={(_, newValue) => {
                      setPricingTierValues(newValue)
                      setSelectedPricingTierId(newValue?.id)
                      setOrganization((prevState) => ({
                        ...prevState,
                        pricing_tier_id: newValue?.id
                      }))
                      setEnabledUpdateBtn(true)
                    }}
                    onInputChange={(_, newInputValue) => {
                      setPricingTierInputValue(newInputValue)
                      setEnabledUpdateBtn(true)
                    }}
                    options={pricingTierData?.data?.pricing_tiers || []}
                    popupIcon={<Icon color="secondary" name="expand_more" />}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Pricing Tier"
                        placeholder={
                          values && values.length > 0
                            ? ''
                            : 'Select pricing tier'
                        }
                        variant="standard"
                      />
                    )}
                    value={pricingTierValues}
                  />
                  {editingPricingTier && (
                    <PricingTierContent
                      editingPricingTier={editingPricingTier}
                      expanded={false}
                      isOverridden={true}
                      setEditingPricingTier={setEditingPricingTier}
                    />
                  )}
                </>
              )}
            </Box>
            <Box
              className={classes.container}
              display="flex"
              flexDirection="column"
              style={{ marginLeft: 20 }}
            >
              <TextField
                disabled
                label="Actual users"
                value={dataPoint?.actual_users || 0}
                variant="standard"
              />
              <TextField
                disabled
                label="Max user last month"
                value={dataPoint?.max_users_last_month || 0}
                variant="standard"
              />
              <TextField
                disabled
                label="Max billable user last month"
                value={maxBillableUsersLastMonth || 0}
                variant="standard"
              />
              <TextField
                disabled
                label="Additional paid user"
                value={additionalPaidUsers || 0}
                variant="standard"
              />
              <TextField
                disabled
                label="Actual customer data point"
                value={dataPoint?.actual_customer || 0}
                variant="standard"
              />
              <TextField
                disabled
                label="Max customer data point last month"
                value={dataPoint?.max_customer || 0}
                variant="standard"
              />

              <AutocompleteX
                ChipProps={{
                  deleteIcon: <Icon name="close" />
                }}
                freeSolo
                inputValue={inputValue}
                multiple
                onChange={(_, newValue) => {
                  setValues(newValue)
                  setEnabledUpdateBtn(true)
                }}
                onInputChange={(_, newInputValue) => {
                  setInputValue(newInputValue)
                  setEnabledUpdateBtn(true)
                }}
                options={organizationTags || []}
                popupIcon={<Icon color="secondary" name="expand_more" />}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tags"
                    placeholder={
                      values && values.length > 0 ? '' : 'Select tags'
                    }
                    variant="standard"
                  />
                )}
                value={values || []}
              />
              <TextField
                disabled
                label="Created date"
                value={formatDateTime(orgInfo?.created_date)}
                variant="standard"
              />
              <TextField
                label="Customer number"
                onChange={handleOrgChange('customer_number')}
                value={orgInfo?.customer_number}
                variant="standard"
              />
              <TextField
                disabled
                label="Actual partner users"
                value={getActualPartnerUsers()}
                variant="standard"
              />
              <TextField
                disabled
                label="Max partner users last month"
                value={getMaxPartnerUsers()}
                variant="standard"
              />
              <TextField
                disabled
                label="Active partnerships"
                value={activePartners.length || 0}
                variant="standard"
              />

              <TextField
                disabled
                label="End customer portal users"
                value={endCustomerPortalUserQuery?.data?.data?.hits || 0}
                variant="standard"
              />
              <TextField
                disabled
                label="Installer portal users"
                value={installerPortalUserQuery?.data?.data?.hits || 0}
                variant="standard"
              />
            </Box>
          </Box>
          <Box>
            <Button
              color="secondary"
              disabled={!enabledUpdateBtn}
              onClick={handleUpdateOrganization}
              style={{ textTransform: 'none', marginTop: 20 }}
              variant="contained"
            >
              {updateOrganization.isLoading ? (
                <CircularProgress color={'inherit'} size={20} />
              ) : (
                'Update'
              )}
            </Button>
            {errorMessage && (
              <Alert
                onClose={() => setErrorMessage('')}
                severity="error"
                style={{ margin: '10px 0px' }}
              >
                {errorMessage}
              </Alert>
            )}
          </Box>
        </>
      )}
    </>
  )
}
